import React from "react";
import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { Box, Paper, Typography } from "@mui/material";
import { formatTime } from "../services";

interface BoxPLotProps {
  data: any[] | undefined;
}

const CustomTooltip = ({ data }: { data: any }) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        maxWidth: "200px",
      }}
    >
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", marginBottom: "8px" }}
      >
        {data.group}
      </Typography>
      <Box>
        <Typography variant="body2"> Number of Submissions: {data.n}</Typography>
      </Box>
      <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
        Quantiles
      </Typography>
      <Typography variant="body2">10%: {formatTime(data.values[0])}</Typography>
      <Typography variant="body2">25%: {formatTime(data.values[1])}</Typography>
      <Typography variant="body2">50%: {formatTime(data.values[2])}</Typography>
      <Typography variant="body2">75%: {formatTime(data.values[3])}</Typography>
      <Typography variant="body2">
        90%: {formatTime(data.values[4])}
      </Typography>
      <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
        Summary
      </Typography>
      <Typography variant="body2">
        mean: {formatTime(Math.round(data.mean))}
      </Typography>
      <Typography variant="body2">min: {formatTime(data.values[0])}</Typography>
      <Typography variant="body2">max: {formatTime(data.values[4])}</Typography>
    </Paper>
  );
};

const AverageTimeBoxPlot: React.FC<BoxPLotProps> = ({ data }) => {
  return (
    <>
      {data && (
        <ResponsiveBoxPlot
          data={data}
          margin={{ top: 60, right: 140, bottom: 60, left: 100 }}
          groupBy="group"
          subGroupBy="subgroup"
          quantiles={[0.1, 0.25, 0.5, 0.75, 0.9]}
          padding={0.12}
          enableGridX={true}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Assignments",
            legendPosition: "middle",
            legendOffset: 32,
            truncateTickAt: 0,
          }}
          axisLeft={{
            format: formatTime,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Time Consumed",
            legendPosition: "middle",
            legendOffset: -80,
            truncateTickAt: 0,
          }}
          colors={{ scheme: "category10" }}
          borderRadius={2}
          borderWidth={2}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.3]],
          }}
          medianWidth={3}
          medianColor={{
            from: "color",
            modifiers: [["darker", 0.3]],
          }}
          whiskerEndSize={0.6}
          whiskerColor={{
            from: "color",
            modifiers: [["darker", 0.3]],
          }}
          tooltip={(e) => {
            
            return <CustomTooltip data={e["data"]} />;
          }}
          motionConfig="stiff"
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemWidth: 60,
              itemHeight: 20,
              itemsSpacing: 3,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              symbolSize: 20,
              symbolShape: "square",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
};

export default AverageTimeBoxPlot;
