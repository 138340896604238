// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/scatterplot
import React from "react";
import { GraphProps } from "../../utils/properties";

const ChangeDistGraph: React.FC<GraphProps> = ({
  data,message
}) => {

   
  
    return (
      <div>
        {data!==undefined ? (
          <div dangerouslySetInnerHTML={{ __html: data }}/>
        ) : (
          <p>{message}</p>
        )}
      </div>
    );
};

export default ChangeDistGraph;
