/* eslint-disable import/no-anonymous-default-export */
import {
  DATA_LOADING,
  DATA_LOADING_FAIL,
    DATA_LOADING_SUCCESS
  } from "../actions/types";

  const initialState = {isLoading:true,data:null};

  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      
      case DATA_LOADING_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: payload.data,
        };
        case DATA_LOADING_FAIL:
          return {
            ...state,
            isLoading: false,
            data: null,
          };
          case DATA_LOADING:
          return {
            ...state,
            isLoading: true,
            data: null,
          };
      default:
        return state;
    }
  }
  