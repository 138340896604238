import React from "react";
import {
  ExampleComponentFactory,
  ExampleEvent,
  ExampleProps,
} from "../../../instructor/ReadAssignment/ActivityTimeLine/model";
import { useTheme } from "@mui/material/styles";
import { Timeline } from "react-svg-timeline";

import StartIcon from "@mui/icons-material/Start";
import PublishIcon from "@mui/icons-material/Publish";
import CommentIcon from '@mui/icons-material/Comment';
import RunCircleIcon from "@mui/icons-material/RunCircle";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";



const CustomizedTimeline: React.FC<ExampleProps> = (props) => {
  const theme = useTheme();
  const PRIMARY = theme.palette.primary.light;
  const eventComponent: ExampleComponentFactory = (
    e: ExampleEvent,
    role: string,
    timeScale: (arg0: any) => any,
    y: number
  ) => {
    const sizeIcon = e.isPinned?42:24;
    const sizeBar = 6;
    const startX = timeScale(e.startTimeMillis)!;
    if (e.endTimeMillis !== undefined) {
      const endX = timeScale(e.endTimeMillis)!;
      const width = endX - startX;
      return (
        <g>
          <rect
            color={PRIMARY}
            fill={PRIMARY}
            stroke={PRIMARY}
            strokeWidth={"1px"}
            x={startX}
            y={y - sizeBar / 2}
            width={width - 5}
            height={sizeBar}
            rx={5}
          />
        </g>
      );
    } else {
      const iconTranslate = `translate(${startX - sizeIcon / 2}, ${
        y - sizeIcon / 2
      })`;
      return (
        <g transform={`${iconTranslate}`}>
          {e.eventId === "start" && (
            <StartIcon
              sx={{
                color: e.color,
                fill: e.color ? e.color : PRIMARY,
                strokeWidth: 2,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("SB-") && (
            <PublishIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("PR-") && (
              <SwapHorizontalCircleIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("CM-") && (
            <CommentIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("OG-") && (
            <RunCircleIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
        </g>
      );
    }
  };
  return <Timeline {...props} eventComponent={eventComponent} />;
};

export default CustomizedTimeline;
