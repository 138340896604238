export const SUBMIT_TOOLTIP =
  "The Submit button allows the instructor to submit the current ongoing version of the document (if, for example, the deadline for the assignment has passed and the instructor wishes to finalize the assignment). For assignments with multiple drafts, the instructor can choose which draft to submit the assignment as.";
export const ENDCOMMENT_TOOLTIP =
  "The End Commenting button should be used by the instructor after they have completed placing their comments on a draft. The system saves a read-only version of the document with the instructor's comments on the draft for later review.";
export const GB_TOOLTIP =
  "The Grade Book allows the instructor to assign grades for various drafts for the assignment.";
export const EVAL_TOOLTIP =
  "The Evaluate button causes the system to provide Effort and Revision scores for the current ongoing version of the text.";
  export const REOPEN_EXCEPTION_TOOLTIP =
  "The Reopen/Exception button allows the instructor to reopen previously submitted final drafts and to provide deadline exceptions for the assignment.";
