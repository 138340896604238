import React, { useState, useEffect } from "react";
import { AnalyticsDashboardProps } from "../../utils/properties";

import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getEssayMapData,
  getHeatMapData,
  getWordChangeData,
} from "../../utils/service";
import { GraphMap } from "../../utils/model";
import ChangeDistGraph from "./EssayMap";
import HeatMap from "./HeatMap";
import WordChange from "./WordChange";

import Transition from "../../utils/Transition";



const AnalyticsDashboard: React.FC<AnalyticsDashboardProps> = (props) => {
  const [graph, setGraph] = useState<string>("0");
  const [message, setMessage] = useState<string>("Loading Graph");
  const [data, setData] = useState<any>();

  useEffect(() => {
    async function getData(target: string) {
      try {
        switch (target) {
          case "EssayMap":
            const apiEM = await getEssayMapData(props.id);
            setData(apiEM.data);
            break;
          case "HeatMap":
            const apiHM = await getHeatMapData(props.id);
            setData(JSON.parse(apiHM.data));
            break;
          case "WordChange":
            const apiWC = await getWordChangeData(props.id);
            setData(apiWC.data);
            break;
        }
      } catch {
        setMessage(`Failed to load ${target}`);
      }
    }

    // @ts-ignore
    getData(GraphMap.get(graph));
  }, [graph, props.id]);

  const handleGraphChange = (e: any) => {
    if (graph === e.target.name) {
      return;
    }
    const target: string = e.target.name;
    setData(undefined);
    setMessage("Loading Graph");
    setGraph(target);
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      // TODO look later
      // @ts-ignore
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: "black" }}
            variant="h6"
            component="div"
          >
            Analytics
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            {["EssayMap", "HeatMap", "WordChange"].map((page, index) => (
              // TODO name prop is the reason why we have to ts ignore
              // @ts-ignore
              <Button
                key={page}
                onClick={(e: any) => handleGraphChange(e)}
                sx={{
                  my: 2,
                  color: "black",
                  display: "block",
                  textTransform: "none",
                }}
                name={index}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      {graph === "0" && (
        <>
          <ChangeDistGraph data={data} message={message} />
        </>
      )}
      {graph === "1" && (
        <>
          <HeatMap data={data} message={message} />
        </>
      )}
      {graph === "2" && (
        <>
          <WordChange data={data} message={message} />
        </>
      )}
    </Dialog>
  );
};

export default AnalyticsDashboard;
