import React from "react";

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";

function MenuDrawer(prop: any) {
  const dispatch = useDispatch();
  // TODO add interface
  return (
    <List sx={{ py: 0 }}>
      {prop.list.map(
        (
          item: any // TODO add interface
        ) => (
          <Tooltip title={prop.isDrawerOpen ? "" : item.label} key={item.id}>
            <ListItemButton
              // key={item.id}
              sx={{
                minHeight: 44,
                justifyContent: prop.isDrawerOpen ? "initial" : "center",
                px: 2.5,
              }}
              component={item.link && Link}
              to={item.link}
              href={item.link}
              onClick={
                item.link !== ""
                  ? undefined
                  : (e: any) => {
                      e.preventDefault();
                      dispatch(item.action());
                    }
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: prop.isDrawerOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>

              <ListItemText
                primary={item.label}
                sx={{ opacity: prop.isDrawerOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </Tooltip>
        )
      )}
    </List>
  );
}

const mapStateToProps = function (state: any) {
  // TODO add interface
  return {
    isDrawerOpen: state.drawer.isDrawerOpen,
  };
};

export default connect(mapStateToProps)(MenuDrawer);
