import React from "react";
import { UserSearchListProps } from "./model";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const UserSearchList: React.FC<UserSearchListProps> = (props) => {
  return (
    <Table component={Paper}>
      <TableHead>
        <TableRow>
          <TableCell>First Name</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>Username</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Roles</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.searchList.map((user) => (
          <TableRow
            onClick={() => props.setSelectedUser(user.id)}
            sx={{
              cursor: "pointer",
            }}
            hover
          >
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.phone}</TableCell>

            <TableCell>
              {user.roles.length > 0 && (
                <ul>
                  {user.roles
                    .filter((role) => role.selected)
                    .map((role) => (
                      <li>{role.name.substring(5)}</li>
                    ))}
                </ul>
              )}
            </TableCell>
            <TableCell>
              <ArrowRightIcon />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default UserSearchList;
