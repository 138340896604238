import React, { useEffect, useState } from "react";
import Main from "../../../elements/public/main";
import { withRouter } from "../../../common/withRouter";
import {
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Grid,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { validate } from "../../../actions/auth";
import { connect } from "react-redux";
import StudentsList from "./Students/StudentsList";
import Details from "./Details/Details";
import RegisterAssignment from "./Assignments/RegisterAssignment";
import Assignments from "./Assignments/Assignments";
import {
  deleteAssignmentAction,
  getClassDetailsAction,
} from "../../../store/ClassDetails/actions";
import { apiIds } from "../../../store/ClassDetails/constants";
import { Assignment, AssignmentInit } from "../../../store/ClassDetails/model";
import { setSnackbarMessage } from "../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../store/UIActions/Snackbar/model";
import AddIcon from "@mui/icons-material/Add";
// import ReviewerTab from "./PeerReview/Reviewer";
import PeerReviewDashboard from "./PeerReview/Dashboard";
import EditClass from "./Details/Edit";
import { tabsIndex } from "./model";
import ClassDashboard from "./Assignments/Analytics/ClassDashboard";
import {
  GraphsProps,
  initDateRange,
  initGraphProps,
  DateRange,
  TabType,
} from "./Assignments/Analytics/models";
import {
  getassignmentTimeSeriesLogs,
  getClassHeatMapLogs,
  getClassLineGraphLogs,
  getclassTimeSeriesLogs,
  getSubmissionsWordCount,
} from "../../../services/classes.service";
import { calculateStandardDeviationAndMean } from "./Assignments/Analytics/services";

function InstructorClassPanelTSX(props: any) {
  useEffect(() => {
    props.dispatch(validate());
    props.dispatch(getClassDetailsAction(props.router.params.classid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classId = props.router.params.classid;

  const [state, setState] = useState({
    id: "",
    loading: false,
    currentTab: 0,
    openModal: false,
    openDeleteModal: false,
    selectedDeleteAssignment: {
      ...AssignmentInit,
    },
    selectedEditAssignment: {},
    modalBody: "",
    modalHeader: "",
    openAssignmentModal: false,
    isEditAssignment: false,
    openPeerReview: false,
    peerReviewAssignmentId: "",
    isEditClass: false,
  });

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const handleAssignmentModalOpen = (
    action: string,
    assignment: Assignment
  ) => {
    if (action === "create") {
      setState({
        ...state,
        openAssignmentModal: true,
        isEditAssignment: false,
      });
    }
    if (action === "edit") {
      setState({
        ...state,
        openAssignmentModal: true,
        isEditAssignment: true,
        selectedEditAssignment: assignment.autoSubmission
          ? {
              ...assignment,
              autoItemsDisable: true,
            }
          : assignment,
      });
    }
  };
  const handleAssignmentModalClose = () => {
    setState({
      ...state,
      openAssignmentModal: false,
      isEditAssignment: false,
      selectedEditAssignment: {},
    });
  };
  const showDesc = (e: any) => {
    const assignment = props.classDetails.assignments.find(
      (item: any) => item.id === e
    );
    setState({
      ...state,
      modalBody: assignment.description,
      modalHeader: assignment.title,
      openModal: true,
      openDeleteModal: false,
    });
  };
  const deleteAssignment = (assignment: Assignment) => {
    setState({
      ...state,
      openDeleteModal: true,
      selectedDeleteAssignment: assignment,
    });
  };

  const isLoading = () => {
    const apiValues = Object.values(apiIds);

    // Convert to a Set for efficient lookup
    const apiSet = new Set(apiValues);

    return props.loader.loadingIds.some((item: string) => apiSet.has(item));
  };

  const handleOpenPeerReview = (assignmentId: string) => {
    setState({
      ...state,
      openPeerReview: true,
      peerReviewAssignmentId: assignmentId,
    });
  };
  const handleClosePeerReview = () => {
    setState({ ...state, openPeerReview: false, peerReviewAssignmentId: "" });
  };

  const handleOpenEditClass = () => {
    setState({
      ...state,
      isEditClass: true,
    });
  };
  const handleCloseEditClass = () => {
    setState({
      ...state,
      isEditClass: false,
    });
  };

  // Analytics states
  const [graphData, setGraphData] = useState<GraphsProps>(initGraphProps);
  // Set initial values for the slider (as timestamps)
  const [dateRange, setDateRange] = useState<DateRange>({
    ...initDateRange,
  });

  const [analyticsTab, setAnalyticsTab] = useState<TabType>("Class Total Time");

  useEffect(() => {
    const getHeadmapData = async () => {
      if (classId) {
        try {
          const heatMapLogs = await getClassHeatMapLogs(classId);
          return { HeatmapProps: JSON.parse(heatMapLogs.data) };
        } catch (error) {
          console.error("Error fetching heatmap data:", error);
          return {};
        }
      }
      return {};
    };

    const getLineGraphData = async () => {
      if (classId) {
        try {
          const lineGraphLogs = await getClassLineGraphLogs(classId);
          return { lineGraphProps: JSON.parse(lineGraphLogs.data) };
        } catch (error) {
          console.error("Error fetching line graph data:", error);
          return {};
        }
      }
      return {};
    };

    const getTimeSeriesData = async () => {
      if (classId) {
        try {
          const timeSeriesLogs = await getclassTimeSeriesLogs(classId);
          return {
            TimeSeriesProps: [
              { id: "Words Changed", data: JSON.parse(timeSeriesLogs.data) },
            ],
          };
        } catch (error) {
          console.error("Error fetching time series data:", error);
          return {};
        }
      }
      return {};
    };

    const getCompariosnData = async () => {
      if (
        props.classDetails.assignments &&
        props.classDetails.assignments.length > 0
      ) {
        try {
          const promises = props.classDetails.assignments.map(
            async (item: any) => {
              try {
                const response = await getassignmentTimeSeriesLogs(item.id);
                return { id: item.title, data: JSON.parse(response.data) };
              } catch (error) {
                return undefined;
              }
            }
          );

          const results = (await Promise.all(promises)).filter(
            (c) => c !== undefined
          );

          return { CompariosnProps: results };
        } catch (error) {
          console.error("Error fetching comparison data:", error);
          return {};
        }
      }
      return {};
    };

    const getWPM = async () => {
      const submissions = props.classDetails.students
        .map((student: any) => student.submissions)
        .flat()
        .filter((submission: any) => submission.totalTime > 0)
        .map((submission: any) => {
          return {
            assignmentId: submission.assignmentId,
            totalTime: submission.totalTime,
            submissionId: submission.submissionId,
          };
        });
      // getSubmissionsWordCount
      const submissionids = submissions.map(
        (submission: any) => submission.submissionId
      );
      const response = await getSubmissionsWordCount(submissionids);
      const data = props.classDetails.assignments
        .map((assignment: any) => {
          const filteredSubmissions = submissions.filter(
            (c: any) => c.assignmentId === assignment.id
          );


          const mergedSubmission = filteredSubmissions.map((c: any) => {
            const tw = response.data.find((d: any) => c.submissionId ===  d.id );
            return ({ ...c, totalLen: tw.totalLen });
          });
          if (mergedSubmission.length === 0) return undefined;

          const times = mergedSubmission.map((c: any) => Math.ceil(c.totalLen / (c.totalTime /60)));
          const statistics = calculateStandardDeviationAndMean(times);
          return mergedSubmission.map((c: any) => ({
            group: assignment.title,
            subgroup: "Words per minutes",
            mu: statistics.mean,
            sd: statistics.sd,
            n: times.length,
            value: Math.ceil(c.totalLen / (c.totalTime /60)),
          }));
        })
        .flat()
        .filter((c: any) => c !== undefined);

      return { WPMProps: data };
    };

    const loadGraphData = async () => {
      const methods = [
        getWPM,
        getTimeSeriesData,
        getCompariosnData,
        getLineGraphData,
        getHeadmapData,
      ];

      for (const method of methods) {
        const result = await method();
        setGraphData((prev) => ({ ...prev, ...result })); // Merge new data with the existing state
      }
    };

    if (props.classDetails.id !== "") {
      document.title = `Antecedent Writing Analytics - ${props.classDetails.className}`;
      loadGraphData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.classDetails]);

  return (
    <Main>
      {props.classDetails && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={state.currentTab}
              onChange={(event: any, newValue: any) => {
                if (!state.isEditClass) {
                  setState({
                    ...state,
                    currentTab: newValue,
                  });
                } else {
                  props.dispatch(
                    setSnackbarMessage({
                      type: AlertTypes.WARNING,
                      showSnackBar: true,
                      message:
                        "You can not switch between tabs while you edit the class details",
                    })
                  );
                }
              }}
              aria-label="Class details tabs"
              centered
            >
              <Tab label="Details" {...a11yProps(tabsIndex.DETAILS)} />
              <Tab
                label="Students"
                {...a11yProps(tabsIndex.STUDENTS)}
                disabled={state.isEditClass}
              />
              <Tab
                label="Assignments"
                {...a11yProps(tabsIndex.ASSIGNMENTS)}
                disabled={state.isEditClass}
              />
              <Tab
                label="Analytics"
                disabled={state.isEditClass}
                {...a11yProps(tabsIndex.ANALYTICS)}
              />
            </Tabs>
          </Box>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
              {state.currentTab === tabsIndex.DETAILS && (
                <>
                  {!state.isEditClass && (
                    <Details
                      className={props.classDetails.className}
                      subjectArea={props.classDetails.subjectArea}
                      studentLevel={props.classDetails.studentLevel}
                      startDate={props.classDetails.startDate}
                      endDate={props.classDetails.endDate}
                      classTimeZone={props.classDetails.classTimeZone}
                      handleOpenEdit={handleOpenEditClass}
                    />
                  )}
                  {state.isEditClass && (
                    <EditClass
                      handleEditClose={handleCloseEditClass}
                      dispatch={props.dispatch}
                    />
                  )}
                </>
              )}
              {state.currentTab === tabsIndex.STUDENTS && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StudentsList
                      students={props.classDetails.students}
                      assignments={props.classDetails.assignments}
                    />
                  </Grid>
                </Grid>
              )}
              {state.currentTab === tabsIndex.ASSIGNMENTS && (
                <>
                  <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    onClick={() =>
                      handleAssignmentModalOpen("create", AssignmentInit)
                    }
                  >
                    Add assignment
                  </Button>
                  <br />
                  <br />
                  <br />
                  <Dialog
                    open={state.openAssignmentModal}
                    // todo: remove onclose and add a iconbutton for it
                    // onClose={handleAssignmentModalClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth
                    maxWidth="md"
                    disableEscapeKeyDown={true}
                    disableEnforceFocus={true}
                    scroll="paper"
                  >
                    <RegisterAssignment
                      classId={props.router.params.classid}
                      dispatch={props.dispatch}
                      closeAssignmentModal={handleAssignmentModalClose}
                      isEdit={state.isEditAssignment}
                      selectedEditAssignment={state.selectedEditAssignment}
                      classTimeZone={props.classDetails.classTimeZone}
                    />
                  </Dialog>

                  <Assignments
                    assignments={props.classDetails.assignments}
                    students={props.classDetails.students}
                    showDesc={showDesc}
                    deleteAssignment={deleteAssignment}
                    editAssignment={(assignment: Assignment) => {
                      handleAssignmentModalOpen("edit", assignment);
                    }}
                    classTimeZone={props.classDetails.classTimeZone}
                    openPeerReview={handleOpenPeerReview}
                  />
                </>
              )}
              {state.currentTab === tabsIndex.ANALYTICS && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ClassDashboard
                      data={graphData}
                      setData={setGraphData}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      currentTab={analyticsTab}
                      setCurrentTab={setAnalyticsTab}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      <Dialog
        onClose={() => {
          setState({
            ...state,
            modalBody: "",
            modalHeader: "",
            openModal: false,
          });
        }}
        disableEscapeKeyDown={true}
        open={state.openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>
          {state.modalHeader} <Divider />{" "}
        </DialogTitle>
        <Typography variant="body2" mx={4} mb={2}>
          {state.modalBody}
        </Typography>
      </Dialog>

      <Dialog
        onClose={() => {
          setState({
            ...state,
            openDeleteModal: false,
            // selectedDeleteAssignment: { title: "", id: "" },
          });
        }}
        disableEscapeKeyDown={true}
        open={state.openDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Delete assignment
          <Divider />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete assignment{" "}
              <b>{state.selectedDeleteAssignment?.title}</b> ?
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState({
                ...state,
                openDeleteModal: false,
                // selectedDeleteAssignment: { title: "", id: "" },
              });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await props.dispatch(
                deleteAssignmentAction(state.selectedDeleteAssignment?.id)
              );
              setState({
                ...state,
                openDeleteModal: false,
              });
              props.dispatch(
                setSnackbarMessage({
                  type: AlertTypes.SUCCESS,
                  showSnackBar: true,
                  message: `Successfully deleted assignment ${state.selectedDeleteAssignment?.title}!`,
                })
              );
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <PeerReviewDashboard
        open={state.openPeerReview}
        handleClose={handleClosePeerReview}
        assignmentId={state.peerReviewAssignmentId}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        open={isLoading()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Main>
  );
}
// TODO add interface
function mapStateToProps(state: any) {
  const { classDetails, loader } = state;
  return {
    classDetails,
    loader,
  };
}

export default withRouter(connect(mapStateToProps)(InstructorClassPanelTSX));
