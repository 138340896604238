import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSupportClose } from "../../store/UIActions/Support/actions";
import CloseIcon from "@mui/icons-material/Close";
import { createIssue, getProjectTrackers } from "./services";
import { SupportRequest, Tracker, initSupportRequest } from "./model";
import { User } from "../../services/models/auth-token.model";
import { setSnackbarMessage } from "../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../store/UIActions/Snackbar/model";
import { MESSAGES } from "./constraints";

const SupportDialog = () => {
  const dispatch = useDispatch();
  const { isSupportDialogOpen } = useSelector((state: any) => state.support);
  const user: User = useSelector((state: any) => state.auth.user);

  const [options, setOption] = useState<Tracker[]>([]);
  const [formData, setFormData] = useState<SupportRequest>(
    user
      ? {
          ...initSupportRequest,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        }
      : initSupportRequest
  );
  const handleClose = () => {
    dispatch(setSupportClose());
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await getProjectTrackers();
        if (response.status === 200) {
          setOption(response.data);
          localStorage.setItem("supportOptions", JSON.stringify(response.data));
          return;
        }
        throw new Error("Unable to fetch data");
      } catch (e: any) {
        console.error(e);
        return;
      } finally {
      }
    };
    const supportOptionsJSON = localStorage.getItem("supportOptions");

    if (!supportOptionsJSON) {
      fetchOptions();
    } else {
      setOption(JSON.parse(supportOptionsJSON));
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response = await createIssue(formData);
    try {
      if (response.status === 200) {
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.SUCCESS,
            showSnackBar: true,
            message: MESSAGES.SUCCESS,
          })
        );
        setFormData(initSupportRequest);
        handleClose();
        return;
      }
      throw new Error(MESSAGES.ERROR);
    } catch (e: any) {
      let errorMessage = MESSAGES.ERROR;

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      } else if (e.message) {
        errorMessage = e.message;
      }
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: errorMessage,
        })
      );
    }
  };
  return (
    <Dialog
      open={isSupportDialogOpen}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Request Support
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DialogContentText>
          <FormControl>
            <FormLabel id="support-radio-buttons-group">Support type</FormLabel>
            <RadioGroup
              aria-labelledby="support-radio-buttons-group"
              name="option"
              value={formData.option}
              row
              onChange={handleChange}
              
            >
              
              {isSupportDialogOpen && options.map((item) => (
                <FormControlLabel
                  value={item.id}
                  control={<Radio />}
                  label={item.name}
                  key={`option-${item.id}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <TextField
            required
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            onChange={handleChange}
            value={formData.name}
            disabled={user ? true : false}
          />
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            margin="normal"
            onChange={handleChange}
            value={formData.email}
            disabled={user ? true : false}
          />
          <TextareaAutosize
            minRows={7}
            aria-label="maximum height"
            name="message"
            id="message"
            placeholder="Message"
            onChange={handleChange}
            defaultValue={formData.message}
            value={formData.message}
            required
            className="textareaWhite"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportDialog;
