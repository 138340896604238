import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import drawer from "../store/Drawer/reducer";
import assignment from "../store/ReadAssignment/reducer";
import loader from "../store/UIActions/Loading/reducer";
import data from "./data";
import snackbar from "../store/UIActions/Snackbar/reducer";
import classDetails from "../store/ClassDetails/reducer";
import rewind from "../store/Rewind/reducer";
import support from "../store/UIActions/Support/reducer";

export default combineReducers({
  auth,
  message,
  drawer,
  data,
  assignment,
  loader,
  classDetails,
  snackbar,
  rewind,
  support,
});
