import { ShowAnalyticsProps } from "./model";

export const tooltips = {
  PAUSE: "Student writing stoped. Click to resume.",
  PLAY: "Students are able to write. Click to stop writing.",
  MODIFY: "Edit assignment",
  DELETE: "Delete assignment",
  VISIBLE: "Visible?",
  PEER_REVIEW: "Peer Review",
  ANALYTICS:"Assignment-level analytics"
};

export const ShowAnalyticsStates: ShowAnalyticsProps[] = [
  { type: "NEVER", label: "NEVER" },
  { type: "ALWAYS", label: "Always" },
  { type: "AFTER_GRADE", label: "After Grading" },
];
