import { SnackbarMessage, SnackbarMessageState, initialState } from "./model";

export const SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE";
export const CLEAR_SNACKBAR_MESSAGE = "CLEAR_SNACKBAR_MESSAGE";

export type ActionType = ClearSnackbarMessage | SetSnackbarMessage;

// set snackbar message start
interface SetSnackbarMessage {
  type: typeof SET_SNACKBAR_MESSAGE;
  payload: {
    message: SnackbarMessage;
  };
}

export const setSnackbarMessage = (
  message: SnackbarMessage
): SetSnackbarMessage => ({
  type: SET_SNACKBAR_MESSAGE,
  payload: {
    message,
  },
});

// set snackbar message end

// set clear snackbar message start
interface ClearSnackbarMessage {
  type: typeof CLEAR_SNACKBAR_MESSAGE;
  payload: SnackbarMessageState;
}

export const clearSnackbarMessage = (): ClearSnackbarMessage => ({
  type: CLEAR_SNACKBAR_MESSAGE,
  payload: {
      ...initialState,
  },
});

// set clear snackbar message end
