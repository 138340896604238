import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Box, Divider, Grid, Slider, Stack, Typography } from "@mui/material";
import {
  getDatafilterByTime,
  getMinMaxX,
  getYAverage,
  timeStringToSeconds,
} from "../services";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface MyResponsiveLineProps {
  data: any[];
  maxY: number;
}
const WordChangeGraph: React.FC<MyResponsiveLineProps> = ({ data, maxY }) => {
  const [timeRange, setTimeRange] = useState<string[] | undefined>(undefined);
  const [minMax, setMinMax] = useState<string[] | undefined>(undefined);

  // Convert timestamps back to dayjs date objects for display
  const formatTimestampToDate = (timestamp: number) =>
    dayjs.utc(timestamp * 1000).format("HH:mm:ss");

  useEffect(() => {
    const getMinMax = () => {
      const dateObjects: { x: string; y: number }[] = data.flatMap((x: any) =>
        x.data.map((item: any) => ({ x: item.x, y: item.y }))
      );
      const xMinMix = getMinMaxX(dateObjects);

      setMinMax([
        xMinMix["min"] ? xMinMix["min"] : "00:00:00",
        xMinMix["max"] ? xMinMix["max"] : "00:00:00",
      ]);

      setTimeRange([
        xMinMix["min"] ? xMinMix["min"] : "00:00:00",
        xMinMix["max"] ? xMinMix["max"] : "00:00:00",
      ]);
    };

    getMinMax();
  }, [data]);

  const handleTimeRangeChange = (event: Event, newValue: number | number[]) => {
    const currentvalue = newValue as number[];
    setTimeRange([
      formatTimestampToDate(currentvalue[0]),
      formatTimestampToDate(currentvalue[1]),
    ]);
  };

  const AreaLayer = (dt: any) => {
    return [
      <g>
        {dt.series.map((s: any, index: number) => {
          const average = getYAverage(data, s.id);
          return (
            <line
              x1="0"
              y1={dt.yScale(average)}
              x2={dt.width}
              y2={dt.yScale(average)}
              stroke={s.color}
              stroke-width="2"
            />
          );
        })}
      </g>,
    ];
  };
  return (
    <>
      {minMax && timeRange && (
        <Grid item xs={12} sx={{ ml: 2, mt: 1, maxHeight: "100px" }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{ alignItems: "center", mb: 1, mr: 4 }}
          >
            <Typography id="date-range" gutterBottom>
              Range Selector
            </Typography>
            <Slider
              value={[
                timeStringToSeconds(timeRange[0]),
                timeStringToSeconds(timeRange[1]),
              ]}
              onChange={handleTimeRangeChange}
              valueLabelDisplay="auto"
              min={timeStringToSeconds(minMax[0])}
              max={timeStringToSeconds(minMax[1])}
              // step={24 * 60 * 60 * 1000} // One day step in milliseconds
              valueLabelFormat={(value) => formatTimestampToDate(value)}
              aria-labelledby="date-range"
              // marks={getSliderMarks()}
            />
          </Stack>
        </Grid>
      )}
      {timeRange && (
        <ResponsiveLine
          data={getDatafilterByTime(data, timeRange)}
          curve="monotoneX"
          margin={{ top: 50, right: 60, bottom: 80, left: 120 }}
          layers={[
            "grid",
            "markers",
            "areas",
            "lines",
            "slices",
            "axes",
            "points",
            "legends",
            AreaLayer,
          ]}
          enableSlices={"x"}
          // enablePointLabel={true} // Enable point labels for tooltips
          xScale={{
            type: "time",
            format: "%H:%M:%S",
            precision: "second",
            useUTC: false,
          }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: 0,
            format: "%H:%M:%S",
            legend: "Total Writing Time",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 3,
            legend: "Change in Word Count every Minute",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          colors={{ scheme: "category10" }}
          pointSize={3}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: true,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 10,
              itemWidth: 160,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "right-to-left",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
          sliceTooltip={(props: any) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  border: "1px solid #ccc",
                  fontSize: "10px",
                }}
              >
                {props?.slice.points.map((point: any, index: number) => (
                  <Box key={index} mb={0}>
                    <Divider
                      textAlign="left"
                      sx={{ color: point.serieColor, fontSize: 16 }}
                    >
                      {point.serieId}
                    </Divider>
                    <Typography variant="body2" mt={1}>
                      Essay Time:{" "}
                      <strong>
                        {dayjs(point.data.xFormatted).format("HH:MM:ss")}
                      </strong>
                    </Typography>
                    <Typography variant="body2">
                      Word Changed: <strong>{point.data.y}</strong>
                    </Typography>
                  </Box>
                ))}
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default WordChangeGraph;
