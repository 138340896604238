import React, { Component } from "react";
// tools
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// elements
import Slider from "../../elements/slider";
import CustomAppBar from "../../elements/AppBar";
import SupportDialog from "../../elements/Support/SupportDialog";
import Main from "../../elements/public/main";
import { PATHS } from "./constants";
// Routes
import { routes } from "./links";

class App extends Component {
  // TODO add interface
  constructor(props:any) {
    super(props);
    this.isSlider = this.isSlider.bind(this);
    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  isSlider() {
    return !PATHS.NoSlider.includes(window.location.pathname.toLowerCase());
  }

  isSupported() {
    return process.env.REACT_APP_SUPPORT === "true";
  }

  render() {
    return (
      <Router>
        {this.isSlider() ? <Slider /> : <CustomAppBar />}
        {this.isSupported() && (<SupportDialog />)}
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
          <Route
            path="*"
            element={
              <Main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </Main>
            }
          />
        </Routes>
      </Router>
    );
  }
}

function mapStateToProps(state:any) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
