import { TimeMetrics } from "../../services/models/classes.model";

export const initialState: ReadAssignmentState = {
  submissionData: {
    createDate: "",
    assignmentId: "",
    assignmentTitle: "",
    assignmentDuedate: "",
    assignmentStartDate: "",
    classId: "",
    classTitle: "",
    currentStudentIndex: 0,
    effort: 0,
    grade: 0,
    numberOfStudents: 0,
    revision: 0,
    scoresHistory: [],
    sincerity: 0,
    status: "",
    studentId: "",
    studentName: "",
    lastSubmission: "",
    lastModification: "",
    unoriginality: 0,
    feedbacks: [],
    deadlines: [],
    deadlineSubmissions: [],
    classTimezone: "",
    submissionId: "",
    submissionLog: {
      logs: [],
    },
    autoAccept: false,
  },
  timeMetrics: {
    active: { d: 0, h: 0, m: 0, s: 0 },
    think: { d: 0, h: 0, m: 0, s: 0 },
    real: { d: 0, h: 0, m: 0, s: 0 },
    classRank: -1,
    classNumMembers: -1,
  },
};
export interface ReadAssignmentState {
  submissionData: SubmissionDetails;
  timeMetrics: TimeMetrics;
}

export interface SubmissionDetails {
  createDate: string;
  assignmentId: string;
  assignmentTitle: string;
  assignmentDuedate: string;
  assignmentStartDate: string;
  classId: string;
  classTitle: string;
  currentStudentIndex: number;
  effort: number;
  grade: number;
  numberOfStudents: number;
  revision: number;
  scoresHistory: [];
  sincerity: number;
  status: string;
  studentId: string;
  studentName: string;
  lastSubmission: string;
  lastModification: string;
  unoriginality: number;
  feedbacks: Feedback[];
  submissionLog: {
    logs: Log[];
  };
  deadlines: Deadline[];
  deadlineSubmissions: DeadlineSubmission[];
  classTimezone: string;
  submissionId: string;
  autoAccept: boolean;
}

export interface Deadline {
  draftId: string;
  draftStatus: string;
  draftTitle: string;
  draftType: string;
  draftPointValue: number;
  draftDeadline: Date;
  draftPeerReviewDeadline:null|Date;
}
export interface CommentedFiles {
  fileId: string;
  date: Date;
  version: string;
}
export interface DeadlineSubmission {
  draftSubmissionId: string;
  draftId: string;
  title: string;
  date: Date;
  status: string;
  exception: boolean;
  fileId: string;
  commentedFiles: CommentedFiles[];
  peerReview: PeerReview[];
  grade: number;
}

export interface PeerReview {
  fileId: string;
  timestamp: Date;
  reviewerName: string;
}

export interface SubmissionLogs {
  logs: Log[];
}
export interface Log {
  id: string;
  change: number;
  timestamp: string;
}

interface Feedback {
  comment: string;
  timestamp: string;
  read: boolean;
  draftId: string;
  draftTitle: string;
}
