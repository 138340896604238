import React from "react";
import { Typography } from "@mui/material";

export default function Termscondition() {
  const TC_URL: string | undefined = process.env.REACT_APP_TC;
  return (
    <>
      {TC_URL ? (
        <iframe
          style={{ width: "95vw", height: "90vh" }}
          id="docs-online-viewer"
          title="student Guide"
          src={TC_URL}
        ></iframe>
      ) : (
        <>
          <Typography variant="body2">
            These terms and conditions (the "Terms and Conditions") govern the
            use of www.antecedentwriting.com (the "Site"). This Site is owned
            and operated by Antecedent Writing Analytics (2524556 ALBERTA INC).
          </Typography>
          <Typography variant="body2">
            By using this Site, you indicate that you have read and understand
            these Terms and Conditions and agree to abide by them at all times.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Intellectual Property
          </Typography>
          <Typography variant="body2">
            All content published and made available on our Site is the property
            of Antecedent Writing Analytics and the Site's creators. This
            includes, but is not limited to images, text, logos, documents,
            downloadable files and anything that contributes to the composition
            of our Site.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Accounts
          </Typography>
          <Typography variant="body2">
            When you create an account on our Site, you agree to the following:
          </Typography>

          <ol>
            <li>
              You are solely responsible for your account and the security and
              privacy of your account, including passwords or sensitive
              information attached to that account; and
            </li>
            <li>
              All personal information you provide to us through your account is
              up to date, accurate, and truthful and that you will update your
              personal information if it changes.
            </li>
          </ol>
          <Typography variant="body2">
            We reserve the right to suspend or terminate your account if you are
            using our Site illegally or if you violate these Terms and
            Conditions.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Limitation of Liability
          </Typography>
          <Typography variant="body2">
            Antecedent Writing Analytics and our directors, officers, agents,
            employees, subsidiaries, and affiliates will not be liable for any
            actions, claims, losses, damages, liabilities and expenses including
            legal fees from your use of the Site.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Indemnity
          </Typography>
          <Typography variant="body2">
            Except where prohibited by law, by using this Site you indemnify and
            hold harmless Antecedent Writing Analytics and our directors,
            officers, agents, employees, subsidiaries, and affiliates from any
            actions, claims, losses, damages, liabilities and expenses including
            legal fees arising out of your use of our Site or your violation of
            these Terms and Conditions.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Applicable Law
          </Typography>
          <Typography variant="body2">
            These Terms and Conditions are governed by the laws of the Province
            of Alberta.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Severability
          </Typography>
          <Typography variant="body2">
            If at any time any of the provisions set forth in these Terms and
            Conditions are found to be inconsistent or invalid under applicable
            laws, those provisions will be deemed void and will be removed from
            these Terms and Conditions. All other provisions will not be
            affected by the removal and the rest of these Terms and Conditions
            will still be considered valid.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Changes
          </Typography>
          <Typography variant="body2">
            These Terms and Conditions may be amended from time to time in order
            to maintain compliance with the law and to reflect any changes to
            the way we operate our Site and the way we expect users to behave on
            our Site. We will notify users by email of changes to these Terms
            and Conditions or post a notice on our Site.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Additional Terms
          </Typography>
          <Typography variant="body2">
            Material written on this website may be used by Antecedent Writing
            Analytics to improve the quality of the algorithm or for other
            purposes. Instructors are able to view metrics and other information
            about how students write assignments, including, writing, editing,
            and time series information.
          </Typography>
        </>
      )}
    </>
  );
}
