import React from "react";

import LoyaltyIcon from "@mui/icons-material/Loyalty";
const GradingMethod: string = process.env.REACT_APP_GRADING || "American";

function getGradeByScoreAmerican(e: number): string {
  if (e < 0) {
    return "N";
  }
  if (e < 50) {
    return "F";
  }
  if (e < 60) {
    return "D";
  }
  if (e < 70) {
    return "C";
  }
  if (e < 80) {
    return "B";
  }
  if (e < 90) {
    return "A";
  }
  if (e <= 100) {
    return "A+";
  }

  return "N";
}

function getGradeByScoreGerman(e: number): string {
  if (e < 0) {
    return "N";
  }
  if (e < 30) {
    return "6";
  }
  if (e < 50) {
    return "5";
  }
  if (e < 60) {
    return "4";
  }
  if (e < 70) {
    return "3";
  }
  if (e < 80) {
    return "2";
  }
  if (e <= 100) {
    return "1";
  }

  return "N";
}

const GradeByScore: { [key: string]: (e: number) => string } = {
  American: getGradeByScoreAmerican,
  German: getGradeByScoreGerman,
};

const getGradeByScore = GradeByScore[GradingMethod];

export { getGradeByScore };

export function getGradeByDigitAmerican(e: number) {
  if (e === 0) {
    return "F";
  }
  if (e === 1) {
    return "D";
  }
  if (e === 2) {
    return "C";
  }
  if (e === 3) {
    return "B";
  }
  if (e === 4) {
    return "A";
  }
  if (e === 5) {
    return "A+";
  }

  return "N";
}

export function getGradeByDigitGerman(e: number) {
  if (e === 0) {
    return "5";
  }
  if (e === 1) {
    return "4";
  }
  if (e === 2) {
    return "3";
  }
  if (e === 3) {
    return "2";
  }
  if (e === 4) {
    return "1";
  }
  if (e === 5) {
    return "1";
  }

  return "N";
}

const GradeByDigit: { [key: string]: (e: number) => string } = {
  American: getGradeByDigitAmerican,
  German: getGradeByDigitGerman,
};

const getGradeByDigit = GradeByDigit[GradingMethod];

export { getGradeByDigit };

export function getScoreColor(e: number) {
  if (e < 0) {
    return "#2ff2f1";
  }
  if (e < 50) {
    return "#ff3924";
  }
  if (e < 60) {
    return "#ff9600";
  }
  if (e < 70) {
    return "#ffe500";
  }
  if (e < 80) {
    return "#cdf03a";
  }
  if (e < 90) {
    return "#2ce574";
  }
  if (e <= 100) {
    return "#20ab56";
  }

  return "#2ff2f1";
}

export function getGradeColor(e: number) {
  if (e === 0) {
    return "#ff3924";
  }
  if (e === 1) {
    return "#ff9600";
  }
  if (e === 2) {
    return "#ffe500";
  }
  if (e === 3) {
    return "#cdf03a";
  }
  if (e === 4) {
    return "#2ce574";
  }
  if (e === 5) {
    return "#20ab56";
  }

  return "#2ff2f1";
}

export function getReverseGradeColor(e: number) {
  if (e < 10) {
    return "#20ab56";
  }
  if (e < 20) {
    return "#2ce574";
  }
  if (e < 30) {
    return "#cdf03a";
  }
  if (e < 35) {
    return "#ffe500";
  }
  if (e < 40) {
    return "#ff9600";
  }
  if (e <= 50) {
    return "#ff3924";
  }

  return "#2ff2f1";
}

export function getIcon(e: string) {
  switch (e) {
    case "severity":
      return <LoyaltyIcon />;

    default:
      return <LoyaltyIcon />;
  }
}
