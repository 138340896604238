import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Alert,
  Stack,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import RateReviewIcon from "@mui/icons-material/RateReview";

import CloseIcon from "@mui/icons-material/Close";
import { DeadlineSubmission } from "../../../../store/ReadAssignment/model";
import { endCommentingAction } from "../../../../store/ReadAssignment/actions";
interface CommentPanelProps {
  open: boolean;
  handleClose: () => void;
}

const CommentPanel: React.FC<CommentPanelProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [draftSubmissionId, setdraftSubId] = React.useState<string>("");
  const [uncommentedDeadlineSubmissions, setUnommentedDeadlineSubmissions] =
    React.useState<DeadlineSubmission[]>([]);
  const deadlineSubmissions: DeadlineSubmission[] = useSelector(
    (state: any) => state.assignment.submissionData.deadlineSubmissions
  );

  const submissionId: string = useSelector(
    (state: any) => state.assignment.submissionData.submissionId
  );

  useEffect(() => {
    try {
      var dfs = deadlineSubmissions;
      dfs = dfs.filter((c) => c.commentedFiles.length === 0);
      setUnommentedDeadlineSubmissions(dfs);
    } catch {}
  }, [deadlineSubmissions]);

  useEffect(() => {
    try {
      const index = uncommentedDeadlineSubmissions.length - 1;
      const defaultSubmission: string =
        uncommentedDeadlineSubmissions.length > 0
          ? uncommentedDeadlineSubmissions[index].draftSubmissionId
          : "";
      setdraftSubId(defaultSubmission);
    } catch {}
  }, [uncommentedDeadlineSubmissions]);

  const handleChange = (event: SelectChangeEvent) => {
    setdraftSubId(event.target.value as string);
  };

  const handleSubmit = async () => {
    // @ts-ignore
    dispatch(endCommentingAction(submissionId, draftSubmissionId));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: "40%",
          margin: 0,
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Button onClick={handleClose} startIcon={<CloseIcon />}></Button>
        End Commenting
      </DialogTitle>
      <DialogContent dividers>
        {uncommentedDeadlineSubmissions.length > 0 ? (
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="draft-select-label">Draft submission</InputLabel>
              <Select
                labelId="draft-select-label"
                id="draft-select"
                value={draftSubmissionId}
                label="Draft submission"
                onChange={handleChange}
              >
                {uncommentedDeadlineSubmissions.map((item) => (
                  <MenuItem
                    value={item.draftSubmissionId}
                    key={item.draftSubmissionId}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Alert severity="warning">
              A copy of current <strong>ongoing</strong> document will be saved
              as the commented on version.
            </Alert>
            <Button
              variant="contained"
              endIcon={<RateReviewIcon />}
              onClick={handleSubmit}
            >
              End Commenting
            </Button>
          </Stack>
        ) : (
          <Alert severity="error">
            End commenting version must associate with a draft submission.
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CommentPanel;
