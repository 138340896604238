/* eslint-disable import/no-anonymous-default-export */
import {
  ActionType,
  CREATE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_SUCCESS,
  DELETE_STUDENT_SUCCESS,
  GET_CLASS_DETAILS_SUCCESS,
  UPDATE_ASSIGNMENT_STOP_SUCCESS,
  UPDATE_ASSIGNMENT_SUCCESS,
  UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS,
  UPDATE_CLASS_DETAILS_SUCCESS,
  UPDATE_DRAFT_PEERREVIEW_DEADLINE_SUCCESS,
} from "./actions";
import { ClassDetails, Student, initialState } from "./model";

export default (state = initialState, action: ActionType): ClassDetails => {
  const { type } = action;

  switch (type) {
    case GET_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload.classDetails,
      };
    case DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        className: action.payload.updateDetails.classname,
        classTimeZone: action.payload.updateDetails.classTimeZone,
        startDate: action.payload.updateDetails.classStartDate.toString(),
        endDate: action.payload.updateDetails.classEndDate.toString(),
        studentLevel: action.payload.studentLevel,
        subjectArea: action.payload.subjectArea,
      };
    case CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        students: [
          ...state.students.filter(
            (student: Student) => student.id !== action.payload.studentId
          ),
        ],
      };
    case UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS:
      return {
        ...state,
        assignments: [...state.assignments].map((item) => {
          if (item.id !== action.payload.assignmentId) return { ...item };
          else {
            return { ...item, visible: action.payload.status };
          }
        }),
      };
    case UPDATE_ASSIGNMENT_STOP_SUCCESS:
      return {
        ...state,
        assignments: [...state.assignments].map((item) => {
          if (item.id !== action.payload.assignmentId) return { ...item };
          else {
            return { ...item, stoped: action.payload.status };
          }
        }),
      };
    case UPDATE_DRAFT_PEERREVIEW_DEADLINE_SUCCESS:
      return {
        ...state,
        assignments: [...state.assignments].map((item) => {
          if (item.id !== action.payload.assignmentId) return { ...item };
          else {
            return { ...item, draftSubmissions: action.payload.drafts };
          }
        }),
      };
    default:
      return state;
  }
};
