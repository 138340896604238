import authHeader from "../../../../services/auth-header";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const APIS = {VALIDATE:"validate"}
const HOST = process.env.REACT_APP_API_URL;
const SUBMISSION = "api/submission"

export const validateSubmissionId = async(submissionId:string) =>{
    const axiosConfig: AxiosRequestConfig = {
      headers: authHeader(),
      method: "GET",
      url: `${HOST}/${SUBMISSION}/${APIS.VALIDATE}`,
      params: {submissionId},
      data: {},
  
    };
    try {
      const apiCall: AxiosResponse<boolean> = await axios(axiosConfig);
      return apiCall;
    } catch (err) {
      throw err;
    }
  }