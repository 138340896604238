import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { format, parseISO } from "date-fns";
import { Box, Divider, Typography } from "@mui/material";

interface TimeSeriesProps {
  data: any | null;
}
const TimeSeries: React.FC<TimeSeriesProps> = ({ data }) => {
  return (
    <>
      {data ? (
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 120, bottom: 100, left: 80 }}
          // xScale={{ type: "linear", min: "auto", max: "auto" }}
          // xFormat=">-.2f"
          yScale={{ type: "linear", min: 0, max: "auto" }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          colors={{ scheme: "category10" }}
          pointSize={14}
          xFormat={(value) => {
            // Ensure value is a string and handle the parsing
            const dateValue =
              typeof value === "string" ? parseISO(value) : new Date(value);
            return format(dateValue, "yyyy-MM-dd HH:mm"); // Format the date as a string
          }}
          axisBottom={{
            format: (value: string) => {
              const dateValue = parseISO(value); // Convert ISO string to Date object
              return format(dateValue, "yyyy-MM-dd HH:mm"); // Format the date as a string
            },
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,

            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            legend: "Words Changed",
            legendPosition: "middle",
            legendOffset: -60,
          }}
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          curve="monotoneX"
          enableSlices={"x"}
          sliceTooltip={(props: any) => {
            // console.log(props);
            return (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  border: "1px solid #ccc",
                  fontSize: "10px",
                }}
              >
                {props?.slice.points.length > 0 && (
                  <Typography variant="body2" mt={1}>
                    Date:{" "}
                    <strong>
                      {format(
                        new Date(props?.slice.points[0].data.x),
                        "yyyy-MM-dd HH:mm"
                      )}
                    </strong>
                  </Typography>
                )}
                {props?.slice.points.map((point: any, index: number) => (
                  <Box
                    key={index}
                    mb={0}
                    sx={{ borderStyle: "ridge none none  none" }}
                  >
                    <Divider
                      textAlign="left"
                      sx={{ color: point.serieColor, fontSize: 16 }}
                    >
                      {point.serieId}
                    </Divider>

                    <Typography variant="body2">
                      Words Changed: <strong>{point.data.y}</strong>
                    </Typography>
                  </Box>
                ))}
              </div>
            );
          }}
        />
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default TimeSeries;
