import React, { useEffect } from "react";
import {
  classTabs,
  DateRange,
  GraphsProps,
  TabType,
  tooltipsText,
} from "./models";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Slider,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import Heatmap from "./Graphs/Heatmap";
import LineGraph from "./Graphs/LineGraph";
import TimeSeries from "./Graphs/TimeSeries";
import dayjs from "dayjs";
import {
  calculateStandardDeviationAndMean,
  extractSliderMarks,
  filterheatMap,
  filterlineGraph,
  filterTimeSeries,
  getHeatMapMinMaxDate,
  getLineMinMaxDate,
  getTimeSeriesMinMax,
} from "./services";
import PieChart from "./Graphs/PieChart";
import AverageTimeBoxPlot from "./Graphs/AverageTimeBoxPlot";
import WPMBoxPlot from "./Graphs/WPMBoxPlot";

interface ClassDashboardProps {
  data: GraphsProps;
  setData: (data: GraphsProps) => void;
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  currentTab: TabType;
  setCurrentTab: (state: TabType) => void;
}

const ClassDashboard: React.FC<ClassDashboardProps> = ({
  data,
  setData,
  dateRange,
  setDateRange,
  currentTab,
  setCurrentTab,
}) => {
  const assignments = useSelector((state: any) =>
    state.classDetails.assignments.map((item: any) => {
      return { id: item.id, title: item.title };
    })
  );

  // Function to handle slider value change
  const handleDateRangeChange = (event: Event, newValue: number | number[]) => {
    const currentvalue = newValue as number[];
    if (currentTab === "Heatmap")
      setDateRange({ ...dateRange, Heatmap: currentvalue });
    if (currentTab === "Hourly Activity")
      setDateRange({ ...dateRange, LineGraph: currentvalue });
    if (currentTab === "Words Changed")
      setDateRange({ ...dateRange, TimeSeries: currentvalue });
    if (currentTab === "Assignment Activity")
      setDateRange({ ...dateRange, Comparison: currentvalue });
  };

  // Convert timestamps back to dayjs date objects for display
  const formatTimestampToDate = (timestamp: number) =>
    dayjs(timestamp).format("YYYY-MM-DD");

  useEffect(() => {
    const setCurrentDateRange = async () => {
      if (
        currentTab === "Heatmap" &&
        !dateRange.HeatMapMinMax &&
        data.HeatmapProps
      ) {
        const heatmapMinMax = await getHeatMapMinMaxDate(data.HeatmapProps);
        setDateRange({
          ...dateRange,
          Heatmap: heatmapMinMax,
          HeatMapMinMax: heatmapMinMax,
        });
      } else if (
        currentTab === "Hourly Activity" &&
        !dateRange.LineGraph &&
        data.lineGraphProps
      ) {
        const linegraphMinMax = await getLineMinMaxDate(data.lineGraphProps);
        setDateRange({
          ...dateRange,
          LineGraph: linegraphMinMax,
          LineGraphMinMax: linegraphMinMax,
        });
      } else if (
        currentTab === "Words Changed" &&
        !dateRange.TimeSeries &&
        data.TimeSeriesProps
      ) {
        const timeSeriesMinMax = await getTimeSeriesMinMax(
          data.TimeSeriesProps
        );
        setDateRange({
          ...dateRange,
          TimeSeriesMinMax: timeSeriesMinMax,
          TimeSeries: timeSeriesMinMax,
        });
      } else if (
        currentTab === "Assignment Activity" &&
        !dateRange.Comparison &&
        data.CompariosnProps
      ) {
        const compariosnMinMax = await getTimeSeriesMinMax(
          data.CompariosnProps
        );
        setDateRange({
          ...dateRange,
          Comparison: compariosnMinMax,
          ComparisonMinMax: compariosnMinMax,
        });
      }
    };

    setCurrentDateRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, data]);

  const showSlider = (): boolean => {
    if (
      currentTab === "Heatmap" &&
      dateRange.Heatmap &&
      dateRange.HeatMapMinMax
    ) {
      return true;
    } else if (
      currentTab === "Hourly Activity" &&
      dateRange.LineGraph &&
      dateRange.LineGraphMinMax
    ) {
      return true;
    } else if (
      currentTab === "Words Changed" &&
      dateRange.TimeSeries &&
      dateRange.TimeSeriesMinMax
    ) {
      return true;
    } else if (
      currentTab === "Assignment Activity" &&
      dateRange.Comparison &&
      dateRange.ComparisonMinMax
    ) {
      return true;
    }
    return false;
  };

  const getMinmaxValue = (): number[] => {
    if (currentTab === "Heatmap" && dateRange.HeatMapMinMax) {
      return dateRange.HeatMapMinMax;
    } else if (currentTab === "Hourly Activity" && dateRange.LineGraphMinMax) {
      return dateRange.LineGraphMinMax;
    } else if (currentTab === "Words Changed" && dateRange.TimeSeriesMinMax) {
      return dateRange.TimeSeriesMinMax;
    } else if (
      currentTab === "Assignment Activity" &&
      dateRange.ComparisonMinMax
    ) {
      return dateRange.ComparisonMinMax;
    }

    return [];
  };

  const getSliderValue = (): number[] => {
    if (currentTab === "Heatmap" && dateRange.Heatmap) {
      return dateRange.Heatmap;
    } else if (currentTab === "Hourly Activity" && dateRange.LineGraph) {
      return dateRange.LineGraph;
    } else if (currentTab === "Words Changed" && dateRange.TimeSeries) {
      return dateRange.TimeSeries;
    } else if (currentTab === "Assignment Activity" && dateRange.Comparison) {
      return dateRange.Comparison;
    }
    return [];
  };

  const getSliderMarks = (): any[] => {
    if (
      currentTab === "Heatmap" &&
      dateRange.Heatmap &&
      dateRange.HeatMapMinMax
    ) {
      return extractSliderMarks(dateRange.HeatMapMinMax, dateRange.Heatmap);
    } else if (
      currentTab === "Hourly Activity" &&
      dateRange.LineGraph &&
      dateRange.LineGraphMinMax
    ) {
      return extractSliderMarks(dateRange.LineGraphMinMax, dateRange.LineGraph);
    } else if (
      currentTab === "Words Changed" &&
      dateRange.TimeSeries &&
      dateRange.TimeSeriesMinMax
    ) {
      return extractSliderMarks(
        dateRange.TimeSeriesMinMax,
        dateRange.TimeSeries
      );
    } else if (
      currentTab === "Assignment Activity" &&
      dateRange.Comparison &&
      dateRange.ComparisonMinMax
    ) {
      return extractSliderMarks(
        dateRange.ComparisonMinMax,
        dateRange.Comparison
      );
    }

    return [];
  };

  const submissions = useSelector((state: any) =>
    state.classDetails.students
      .map((student: any) => student.submissions)
      .flat()
      .filter((submission: any) => submission.totalTime > 0)
      .map((submission: any) => {
        return {
          assignmentId: submission.assignmentId,
          totalTime: submission.totalTime,
        };
      })
  );

  const getAssignmentTitle = (id: string): string => {
    try {
      const title = assignments.find((c: any) => c.id === id)?.title;
      return title ? title : id;
    } catch {
      return id;
    }
  };

  const getPieChartData = () => {
    const groupedById = submissions.reduce(
      (acc: { [key: number]: number }, item: any) => {
        if (!acc[item.assignmentId]) {
          acc[item.assignmentId] = 0;
        }
        acc[item.assignmentId] += item.totalTime;
        return acc;
      },
      {}
    );

    const result = Object.entries(groupedById).map(
      ([assignmentId, totalTime]) => {
        return {
          id: getAssignmentTitle(assignmentId), // Assuming getAssignmentTitle returns a string
          label: getAssignmentTitle(assignmentId),
          value: totalTime,
        };
      }
    );

    return result;
  };

  const isDisabled = (tab: TabType): boolean => {
    switch (tab) {
      case "Heatmap":
        return data.HeatmapProps === null ? true : false;
      case "Words Changed":
        return data.TimeSeriesProps === null ? true : false;
      case "Assignment Activity":
        return data.CompariosnProps === null ? true : false;
      case "Hourly Activity":
        return data.lineGraphProps === null ? true : false;
      case "WPM Averages":
        return data.WPMProps === null ? true : false;
      default:
        return false;
    }
  };

  const getAverageTimeData = () => {
    return assignments
      .map((assignment: any) => {
        const filteredSubmissions = submissions.filter(
          (c: any) => c.assignmentId === assignment.id
        );
        if (filteredSubmissions.length === 0) return undefined;

        const times = filteredSubmissions.map((c: any) => c.totalTime);
        const statistics = calculateStandardDeviationAndMean(times);
        return filteredSubmissions.map((c: any) => ({
          group: assignment.title,
          subgroup: "Total Time",
          mu: statistics.mean,
          sd: statistics.sd,
          n: times.length,
          value: c.totalTime,
        }));
      })

      .flat()
      .filter((c: any) => c !== undefined);
    // return submissions;
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          minHeight: "75vh",
        }}
      >
        <Tabs
          orientation="vertical"
          sx={{ borderRight: 1, borderColor: "divider" }}
          variant="scrollable"
          value={currentTab}
          onChange={(event: React.SyntheticEvent, newValue: TabType) =>
            setCurrentTab(newValue)
          }
        >
          {classTabs.map((tab: TabType, index: number) => (
            <Tooltip title={tooltipsText[tab]} placement="right-end">
              <Tab
                key={`analytic_tab_${index}`}
                id={`analytic_tab_${index}`}
                aria-controls={`simple-tabpanel-${index}`}
                label={
                  <>
                    {tab}{" "}
                    {isDisabled(tab) && (
                      <CircularProgress color="inherit" size={16} />
                    )}
                  </>
                }
                value={tab}
                // onClick={() => setCurrentTab(tab)}
                disabled={isDisabled(tab)}
              />
            </Tooltip>
          ))}
        </Tabs>
        <Grid container>
          <Grid item xs={12} sx={{ ml: 2, mt: 1, maxHeight: "100px" }}>
            <Divider textAlign="center" sx={{ fontSize: 20 }} variant="inset">
              Class-level Analytics
            </Divider>
          </Grid>
          {showSlider() &&
            (() => {
              const minmax = getMinmaxValue();
              return (
                <Grid item xs={12} sx={{ ml: 2, mt: 1, maxHeight: "100px" }}>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1, mr: 4 }}
                  >
                    <Typography id="date-range" gutterBottom>
                      Date Selector
                    </Typography>
                    <Slider
                      value={getSliderValue()}
                      onChange={handleDateRangeChange}
                      valueLabelDisplay="auto"
                      min={minmax[0]}
                      max={minmax[1]}
                      step={24 * 60 * 60 * 1000} // One day step in milliseconds
                      valueLabelFormat={(value) => formatTimestampToDate(value)}
                      aria-labelledby="date-range"
                      marks={getSliderMarks()}
                    />
                  </Stack>
                </Grid>
              );
            })()}

          <Grid
            item
            xs={12}
            sx={{
              ml: 2,
              maxHeight: "80vh",
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-start",
            }}
          >
            {currentTab === "Heatmap" && dateRange.Heatmap && (
              <Heatmap
                data={filterheatMap(data.HeatmapProps, dateRange.Heatmap)}
              />
            )}

            {currentTab === "Hourly Activity" && dateRange.LineGraph && (
              <>
                {" "}
                <LineGraph
                  data={filterlineGraph(
                    data.lineGraphProps,
                    dateRange.LineGraph
                  )}
                />
              </>
            )}
            {currentTab === "Words Changed" && dateRange.TimeSeries && (
              <TimeSeries
                data={filterTimeSeries(
                  data.TimeSeriesProps,
                  dateRange.TimeSeries
                )}
              />
            )}
            {currentTab === "Assignment Activity" && dateRange.Comparison && (
              <TimeSeries
                data={filterTimeSeries(
                  data.CompariosnProps,
                  dateRange.Comparison
                )}
              />
            )}

            {currentTab === "Class Total Time" && getPieChartData() && (
              <PieChart data={getPieChartData()} />
            )}
            {currentTab === "Time Averages" && (
              <AverageTimeBoxPlot data={getAverageTimeData()} />
            )}
            {currentTab === "WPM Averages" && data.WPMProps && (
              <WPMBoxPlot data={data.WPMProps} />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ClassDashboard;
