import React from "react";
import { NotificationProps } from "./model";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const Notification: React.FC<NotificationProps> = ({
  open,
  handleClose,
  handleDismiss,
}) => {
  const handleAccept = () => {
    handleClose(false);
    handleDismiss();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have unsaved changes. If you close this page, your changes will be
          lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Disagree</Button>
        <Button onClick={handleAccept} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notification;
