import React from "react";
import { ClassListItem } from "../utils/model";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";

import { tocustomTimeZoneSimple } from "../../../services/date.service";
import { Link } from "react-router-dom";

interface ClassGridProps {
  data: ClassListItem[] | undefined;
  loading: boolean;
}

const ClassGrid: React.FC<ClassGridProps> = ({ data,loading }) => {
  if (data === undefined || data.length === 0) {
    return <Typography>No class been found</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="left">Instructor</TableCell>
            <TableCell align="left">Subject Area</TableCell>
            <TableCell align="left">Student Level</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">End Date</TableCell>
            <TableCell align="left">Timezone</TableCell>
            <TableCell align="left">Student#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: ClassListItem, index: number) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {" "}
                <Link to={"/educoordinator/coursedetail/" + row.id} target="_blank">
                  {row.title}
                </Link>
              </TableCell>
              <TableCell>
                {row.instructorFirstName + " " + row.instructorLastName}
              </TableCell>
              <TableCell>{row.subjectArea}</TableCell>
              <TableCell>{row.studentLevel}</TableCell>
              <TableCell>
                {tocustomTimeZoneSimple(row.startDate.toString(), row.timezone)}
              </TableCell>
              <TableCell>
                {tocustomTimeZoneSimple(row.endtDate.toString(), row.timezone)}
              </TableCell>
              <TableCell>{row.timezone}</TableCell>
              <TableCell>{row.numberOfStudents}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        {loading && (
          <TableFooter>
            <TableRow>
              <TableCell>Loading...</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default ClassGrid;
