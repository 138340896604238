import * as React from "react";
import { Button, StepLabel, Step, Stepper, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { toTimeZoneSimple } from "../../../../services/date.service";
import { timeline } from "../model";
import SubmissionNotice from "./SubmissionNotice";

interface DeadLineTimelineProps {
  timeline: timeline[];
  deadlineEnforcement: boolean;
  autoAccept:boolean;
  exception: boolean;
  handleStart: () => void;
  handleSubmit: (draftId: string) => void;
}

const DeadLineTimeline: React.FC<DeadLineTimelineProps> = ({
  timeline,
  deadlineEnforcement,
  autoAccept,
  exception,
  handleStart,
  handleSubmit,
}) => {
  const isAllowedToStart = (): boolean => {
    if (!deadlineEnforcement) return true;
    const reversedTimeline = [...timeline].reverse();
    const passed = new Date() < new Date(reversedTimeline[0].date);
    return passed;
  };

  const [notice, setNotice] = React.useState<boolean>(false);

  const getSubmissionIndex = React.useCallback((): number => {
    if (timeline[0].status.startsWith("NONE")) return -1;
    else if (exception) {
      const currentindex = timeline.findIndex((c) =>
        c.status.startsWith("NONE")
      );
      return currentindex !== -1 ? currentindex : timeline.length - 1;
    } else if (timeline.some((c) => c.exception)) {
      return timeline.findIndex((c) => c.exception);
    } else if (deadlineEnforcement) {
      const currentindex = timeline.findIndex(
        (c) => c.status.startsWith("NONE") && new Date(c.date) > new Date()
      );
      return currentindex !== -1 ? currentindex : -2;
    } else {
      const currentindex = timeline.findIndex((c) =>
        c.status.startsWith("NONE")
      );
      return currentindex !== -1 ? currentindex : -2;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeline, deadlineEnforcement]);

  const handleCloseNotice = () => {
    setNotice(false);
  };
  const handleOpenNotice = () => {
    setNotice(true);
  };

  const getOkIndex = () => {
    if (timeline[0].status.startsWith("NONE")) return -1;
    const reversedTimeline = [...timeline].reverse();
    var okIndex = reversedTimeline.findIndex(
      (c) => c.status.startsWith("SUBMITTED") || !c.exception
    );
    okIndex = okIndex === -1 ? -1 : timeline.length - okIndex;
    return okIndex;
  };

  const activeStep = () => {
    if (timeline[0].status.startsWith("NONE")) return -1;
    const reversedTimeline = [...timeline].reverse();
    var okIndex = reversedTimeline.findIndex((c) =>
      c.status.startsWith("SUBMITTED")
    );
    okIndex = okIndex === -1 ? -1 : timeline.length - okIndex;
    return okIndex;
  };

  return (
    <Box sx={{ width: "100%" , height:140}}>
      <Stepper activeStep={activeStep()} alternativeLabel>
        {timeline.map((item, index) => {
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
            warning?: boolean;
          } = {};

          labelProps.optional = (
            <Typography variant="caption">
              {toTimeZoneSimple(item.date.toString())}
              {item.exception && (
                <>
                  <br />
                  Exception
                </>
              )}
            </Typography>
          );
          labelProps.error = item.status === "MISSED";

          return (
            <Step key={`step-${index}`}>
              <StepLabel {...labelProps}>{item.title}</StepLabel>
            </Step>
          );
        })}

        {getSubmissionIndex() === -1 && isAllowedToStart() && (
          <Button variant="contained" onClick={handleStart}>
            Start
          </Button>
        )}
        {(getSubmissionIndex() === timeline.length - 1 ||
          (exception && getSubmissionIndex() === -2)) && (
          <Button variant="contained" onClick={handleOpenNotice}>
            Final Submission
          </Button>
        )}
        {getSubmissionIndex() < timeline.length - 1 &&
          getOkIndex() >= 0 &&
          getSubmissionIndex() !== -2 && (
            <Button variant="contained" onClick={handleOpenNotice}>
              Draft Submission
            </Button>
          )}
      </Stepper>
      {getSubmissionIndex() !== -2 && notice && (
        <SubmissionNotice
          open={notice}
          handleSubmit={handleSubmit}
          handleClose={handleCloseNotice}
          draftTitle={timeline[getSubmissionIndex()].title}
          draftId={timeline[getSubmissionIndex()].draftId}
          autoAccept={autoAccept}
        />
      )}
    </Box>
  );
};

export default DeadLineTimeline;
