import React from "react";
import { ResponsiveLine } from "@nivo/line";
import dayjs from "dayjs";
import Legend from "../../components/Legend";

interface DataPoint {
  x: string;
  y: number;
}

function formatData(e: DataPoint[]) {
  const formattedData = e.map((dataPoint) => ({
    x: dayjs().format(`${dataPoint.x}`),
    y: dataPoint.y,
  }));

  return [
    {
      id: "Word count",
      color: "hsl(10, 70%, 50%)",
      data: formattedData,
    },
  ];
}

interface MyResponsiveLineProps {
  data: DataPoint[];
  maxY: number;
}

const MyResponsiveLine: React.FC<MyResponsiveLineProps> = ({ data, maxY }) => {
  const AreaLayer = (dt: any) => {
    return [
      <g>
        <rect
          y={dt.yScale(25)}
          width={dt.width}
          height={dt.yScale(0) - dt.yScale(25)}
          fill="rgba(178, 222, 39,0.2)"
        />
        <rect
          y={dt.yScale(50)}
          width={dt.width}
          height={dt.yScale(25) - dt.yScale(50)}
          fill="rgba(255, 148, 112,0.2)"
        />
        <rect
          y={dt.yScale(maxY)}
          width={dt.width}
          height={dt.yScale(50) - dt.yScale(maxY)}
          fill="rgba(207, 0, 15,0.2)"
        />
      </g>,
    ];
  };
  return (
    <>
      <ResponsiveLine
        data={formatData(data)}
        curve="monotoneX"
        margin={{ top: 50, right: 60, bottom: 50, left: 120 }}
        layers={[
          "grid",
          "markers",
          "areas",
          AreaLayer,
          "lines",
          "slices",
          "axes",
          "points",
          "legends",
        ]}
        enableSlices={"x"}
        // enablePointLabel={true} // Enable point labels for tooltips
        xScale={{
          type: "time",
          format: "%H:%M:%S",
          precision: "second",
          useUTC: false,
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 10,
          tickPadding: 10,
          tickRotation: 0,
          format: "%H:%M:%S",
          legend: "Total Writing Time",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 3,
          legend: "Change in Word Count every Minute",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "paired" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        sliceTooltip={(props: any) => {
          return (
            <div
              style={{
                background: "white",
                padding: "10px",
                border: "1px solid #ccc",
              }}
            >
              {props?.slice.points[0].data.yFormatted}
            </div>
          );
        }}
        // isInteractive={true}
        // enablePoints={true}
      />

      <Legend
        items={[
          {
            text: "Measured pace",
            color: "rgba(178, 222, 39,0.2)",
          },
          {
            text: "Hurried if sustained",
            color: "rgba(255, 148, 112,0.2)",
          },
          {
            text: "Copied in or rushed",
            color: "rgba(207, 0, 15,0.2)",
          },
        ]}
      />
    </>
  );
};
export default MyResponsiveLine;
