import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import StudentRow from "./StudentsRow";
import {
  ClassDetails,
  Student,
  StudentInit,
} from "../../../../store/ClassDetails/model";
import { withRouter } from "../../../../common/withRouter";
import { connect } from "react-redux";
import { deleteStudentAction } from "../../../../store/ClassDetails/actions";
import { setSnackbarMessage } from "../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../store/UIActions/Snackbar/model";
import { StudentAnalyctic } from "../Assignments/Analytics/models";
import StudentDashboard from "../Assignments/Analytics/StudentDashboard";

interface StudentListProps {
  students?: any;
  assignments?: any;
  dispatch: Function;
  classDetails?: ClassDetails;
}
function StudentsList(props: StudentListProps) {
  const [deleteStudent, setDeleteStudent] = useState(false);
  const [deleteStudentObject, setDeleteStudentObject] = useState<Student>({
    ...StudentInit,
  });
  const [studentAnalytic, setStudentAnalytic] =
    useState<StudentAnalyctic | null>(null);
  const studentByTime: Student[] = props.students
    .filter((c: Student) => c.totalTime > 0)
    .sort((a: Student, b: Student) => b.totalTime - a.totalTime);
  const getRank = (id: string): string => {
    const index = studentByTime.findIndex((c) => c.id === id);
    const result = index > -1 ? `${index + 1}/${studentByTime.length}` : "--";
    return result;
  };
  const deleteStudentFunc = async (student: Student) => {
    setDeleteStudent(true);
    setDeleteStudentObject(student);
  };
  return (
    <>
      {props.students && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Student Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell>Total Writing Time (Class)</TableCell>
                <TableCell>Rank</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO add interface */}
              {props.students.map((row: Student) => (
                <StudentRow
                  key={row.id}
                  row={row}
                  assignments={props.assignments}
                  deleteStudent={deleteStudentFunc}
                  classTimezone={props.classDetails?.classTimeZone}
                  rank={getRank(row.id)}
                  setAnalytics={setStudentAnalytic}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!props.students && (
        <Alert severity="error">No students registered yet.</Alert>
      )}

      <Dialog
        onClose={() => {
          setDeleteStudent(false);
          setDeleteStudentObject({ ...StudentInit });
        }}
        open={deleteStudent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Remove student
          <Divider />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove student{" "}
              <b>
                {deleteStudentObject.firstName} {deleteStudentObject.lastName}
              </b>{" "}
              from <b>{props.classDetails?.className}</b>?
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteStudent(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await props.dispatch(
                deleteStudentAction({
                  classId: props.classDetails?.id || "",
                  studentId: deleteStudentObject.id,
                })
              );
              setDeleteStudent(false);
              setDeleteStudentObject({ ...StudentInit });
              props.dispatch(
                setSnackbarMessage({
                  type: AlertTypes.SUCCESS,
                  showSnackBar: true,
                  message: `Successfully removed student!`,
                })
              );
            }}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <StudentDashboard
        studentId={studentAnalytic ? studentAnalytic.studentId : ""}
        studentName={studentAnalytic ? studentAnalytic.studentName : ""}
        handleClose={() => setStudentAnalytic(null)}
      />
    </>
  );
}
function mapStateToProps(state: any) {
  const { classDetails, loader } = state;
  return {
    classDetails,
    loader,
  };
}
export default withRouter(connect(mapStateToProps)(StudentsList));
