import React from 'react';
import Main from '../../elements/public/main';
import { Grid } from '@mui/material';
import MenuDashboard from './Menus/MenuDashboard';

const AdminDashboard:React.FC = () => {
    return (
        <Main>
           <Grid container flexDirection="row" flexWrap="wrap" spacing={3}>
            <MenuDashboard />
           </Grid>
        </Main>
    );
};

export default AdminDashboard;