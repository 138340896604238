import React from "react";
import { connect } from "react-redux";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { clearSnackbarMessage } from "./store/UIActions/Snackbar/actions";

function AppSnackbar(props: any) {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { message } = props.snackbar;
  return (
    <>
      <Snackbar
        open={message.showSnackBar}
        onClose={() => props.dispatch(clearSnackbarMessage())}
        autoHideDuration={8000}
      >
        <Alert severity={message.type} sx={{ width: "100%" }}>
          {message.message}
        </Alert>
      </Snackbar>
    </>
  );
}

function mapStateToProps(state: any) {
  const { snackbar } = state;
  return { snackbar };
}

export default connect(mapStateToProps)(AppSnackbar);
