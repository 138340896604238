import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../common/withRouter";
import Main from "../../elements/public/main";
// eslint-disable-next-line
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// eslint-disable-next-line
import MyResponsiveLine from "../../elements/graph/line.graph";
import { WordCountChange } from "../../services/models/classes.model";
import { analyzeSubmission } from "../../services/classes.service";

class AnalyzeAssignment extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: this.props.router.params.id,
      writingLog: null,
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    try {
      const analyze = await analyzeSubmission(id);
      this.setState({
        writingLog: analyze.data,
      });
    } catch (err) {}
  }

  render() {
    const { writingLog } = this.state;
    return (
      <Main>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={4}>
            {writingLog && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 400 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Word count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {writingLog.map((row: WordCountChange, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>{++index}</TableCell>
                        <TableCell>
                          {row.x}
                        </TableCell>
                        <TableCell>{row.y}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item sm={12} md={6} lg={8} sx={{ maxHeight: "600px" }}>
            {writingLog && <MyResponsiveLine data={writingLog} maxY={200}/>}
          </Grid>
        </Grid>
      </Main>
    );
  }
}

export default withRouter(connect()(AnalyzeAssignment));
