import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";

import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardContent } from "@mui/material";
import {
  getGradeByDigit,
  getGradeByScore,
  getGradeColor,
  getScoreColor,
} from "../../services/grade.service";
import CircleBar from "./circle.grade.bar";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GradeCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Tooltip title={props.tooltip} placement="right">
      <Card sx={{ mt: 1 }}>
        {props.type === 0 && (
          <>
            <CardHeader
              sx={{ height: 50, mt: 0 }}
              avatar={
                <Avatar
                  sx={{ bgcolor: getScoreColor(props.score) }}
                  aria-label="recipe"
                >
                  {getGradeByScore(props.score)}
                </Avatar>
              }
              action={
                <ExpandMore
                  sx={{ pt: 0 }}
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              }
              title={props.title}
            />

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <CircleBar score={props.score} />
              </CardContent>
            </Collapse>
          </>
        )}
        {props.type === 1 && (
          <>
            <CardHeader
              sx={{ height: 50, mt: 0 }}
              avatar={
                <Avatar
                  sx={{ bgcolor: getGradeColor(props.score) }}
                  aria-label="recipe"
                >
                  {getGradeByDigit(props.score)}
                </Avatar>
              }
              title={props.title}
            />
          </>
        )}
      </Card>
    </Tooltip>
  );
}
