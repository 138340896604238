import React from "react";
import { StudentDetailsProps } from "../../utils/properties";
import { Box, Card, IconButton, Typography, CardContent } from "@mui/material";

import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { getNextSubmissionId } from "../../utils/service";

const StudentDetails: React.FC<StudentDetailsProps> = ({ data }) => {
  const handleMove = async (direction: number) => {
    const getSubmissionId = await getNextSubmissionId(
      data.classId,
      data.currentStudentIndex,
      data.assignmentId,
      direction
    );
    window.location.replace(
      "/educoordinator/ReadSubmission/" + getSubmissionId.data
    );
  };

  return (
    <Card>
      <Box sx={{ display: "flex", alignItems: "center", px: 1, pb: 1 }}>
        <IconButton
          aria-label="previous"
          sx={{ mr: "auto" }}
          onClick={() => handleMove(-1)}
          disabled={data.currentStudentIndex === 0}
        >
          <ChevronLeftOutlinedIcon />
        </IconButton>

        <IconButton aria-label="Indicator">
          <Typography variant="h2">
            {data.currentStudentIndex + 1} /{data.numberOfStudents}
          </Typography>
        </IconButton>

        <IconButton
          aria-label="next"
          sx={{ ml: "auto" }}
          onClick={() => handleMove(1)}
          disabled={data.numberOfStudents === data.currentStudentIndex + 1}
        >
          <ChevronRightOutlinedIcon />
        </IconButton>
      </Box>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {data.classTitle}
        </Typography>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          variant="subtitle2"
        >
          {data.assignmentTitle}
        </Typography>
        <Typography variant="h5" component="div">
          {data.studentName}
        </Typography>
        <p>
          <b>Status:</b> {data.status}
        </p>
      </CardContent>
    </Card>
  );
};

export default StudentDetails;
