import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import { PartnerDetails } from "./constants";

const Partners = (props: any) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ py: 5}}
      {...props}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", my: 5 }}
      >
        <Typography variant="h2" color="primary" sx={{ fontWeight: "bold" }}>
          Partners
        </Typography>
      </Grid>
      <Grid xs={8}>
        <Grid container spacing={3} sx={{justifyContent:"center"}}>
          {PartnerDetails.map((item) => (
            <Grid item xs={12} md={3} sx={{display:"flex",justifyContent:"center", }}>
                <Link href={item.Link} target="_blank" ><img src={item.Media} alt={item.caption} height="50px" /></Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Partners;
