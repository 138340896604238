import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const HOST = process.env.REACT_APP_API_URL;

export const submitContact = async (
  name: string,
  email: string,
  message: string
) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${HOST}/api/email/sendcontact`,
    data: {
      name,
      email,
      message,
    },
  };
  try {
    const apiCall: AxiosResponse<String> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
