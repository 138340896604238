import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { MENUS } from "./constatnts";
import { Link } from "react-scroll";

const CustomAppBar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ paddingY: 1, display: { xs: "none", md: "flex" }, mr: 15 }}
          >
            <img src="/header.png" alt="logo" height="48px" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {MENUS.options.map((page, index) => (
                <MenuItem
                  key={`pagek-${index}`}
                  onClick={handleCloseNavMenu}
                  component="a"
                  // href={page.link}
                >
                  <Link
                  to={page.link}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  spy={true}
                >
                  {page.caption}
                </Link>
                </MenuItem>
              ))}
              <Divider />
              {MENUS.accounts.map((page, index) => (
                <MenuItem
                  key={`pageka-${index}`}
                  onClick={handleCloseNavMenu}
                  component="a"
                  href={page.link}
                >
                  <Typography textAlign="center">{page.caption}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ paddingY: 1, display: { xs: "flex", md: "none" }, mr: 1 }}>
            <img src="/header.png" alt="logo" height="48px" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {MENUS.options.map((page, index) => (
              <Button
                key={`page-${index}`}
                sx={{
                  my: 2,
                  color: "black",
                  display: "block",
                  mx: 0,
                  minWidth: 104,
                }}
              >
                <Link
                  to={page.link}
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                >
                  {page.caption}
                </Link>
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "contents" },
              justifyContent: "right",
            }}
          >
            {MENUS.accounts.map((page, index) => (
              <Button
                key={`page-${index}`}
                // onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block", mx: 1 }}
                href={page.link}
                variant={page.type === "o" ? "outlined" : "contained"}
              >
                {page.caption}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default CustomAppBar;
