import React, { useEffect, useState } from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import { useDispatch, useSelector } from "react-redux";
import { setCurrent } from '../../../../store/Rewind/actions';
interface Log {
  change: number;
  // Add other properties if present in your data
}

interface Assignment {
  submissionData: {
    submissionLog: {
      logs: Log[];
      // Add other properties if present in your data
    };
    // Add other properties if present in your data
  };
}



const ChangeLineGraph: React.FC = () => {
  const { current } = useSelector((state: any) => state.rewind);
  const assignment: Assignment = useSelector((state: any) => state.assignment);
  const dispatch =  useDispatch();
  useEffect(() => {
    getMinMax();
    // console.debug("Assignment loaded");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);




  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(250);

  const getMinMax =  () => {
    const { logs } = assignment.submissionData.submissionLog;
    let minChange = 0;
    let maxChange = 100;

    logs.forEach((log) => {
      if (log.change < minChange) {
        minChange = log.change;
      }
      if (log.change > maxChange) {
        maxChange = log.change;
      }
    });
    setMin(minChange);
    setMax(maxChange)
  };
  
  
  useEffect(() => {
    const dataPoint = current === assignment.submissionData.submissionLog.logs.length -1? current: current -1;
    const data: Serie[] = [
      {
        id: "line",
        data: assignment.submissionData.submissionLog.logs.map(
          (log: Log, index: number) => ({
            x: index,
            y: log.change,
          })
        ),
      },
      {
        id: "Current",
        data: [
          {
            x: dataPoint,
            y: min,
          },
          {
            x: dataPoint,
            y: max,
          },
        ],
      },
    ];

    setData(data);
  }, [current, min, max, assignment.submissionData.submissionLog.logs]);

  const [data, setData] = useState<Serie[]>([])

  

  const handlePointClick = (point: any) => {
    dispatch(setCurrent(point.data.x));
  };

  return (
    
    <div style={{ height: "6vh" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 2, bottom: 2, left: 2 }}
        xScale={{ type: "linear", min: "auto", max: "auto" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          legend: "Version",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={null}
        curve="monotoneX"
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        useMesh={true}
        colors={{ scheme: "category10" }}
        pointColor={{ theme: "background" }}
        pointBorderColor={{ from: "serieColor" }}
        onClick={(point) => {
          handlePointClick(point);
        }}
        tooltip={({ point }) => (
          <div
            style={{
              position: "absolute",
              //   left: `${point.x + 10}px`, // Offset from the point's x-coordinate
              //   top: `${point.y + 10}px`, // Offset from the point's y-coordinate
              background: "white",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          >
            <strong>Frame#{point.data.xFormatted}</strong>
            <br />
            Word Change: {point.data.yFormatted}
          </div>
        )}
      />
    </div>
  );
};

export default ChangeLineGraph;
