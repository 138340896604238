import {
  Backdrop,
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Slider,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { validate } from "../../actions/auth";
import { getSubmissionTextLogs } from "../../actions/data";
import { withRouter } from "../../common/withRouter";
import Main from "../../elements/public/main";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import * as dateService from "../../services/date.service";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { showTextDiff } from "../../services/classes.service";

class ShowProgress extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.setVersion = this.setVersion.bind(this);
    this.loadDocument = this.loadDocument.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.playLogs = this.playLogs.bind(this);
    this.state = {
      id: this.props.router.params.id,
      current: 1,
      currentDoc: null,
      isPlaying: false,
      sleepTime: 1000,
      content: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(validate());
    this.props
      .dispatch(getSubmissionTextLogs(this.state.id))
      .then(async (response: any) => {
        // TODO add interface

        this.setState({
          current: 1,
          currentDoc: this.props.data.logs[1],
        });
        await this.loadDocument();
      });
  }

  handleNext() {
    const current_log = this.state.current + 1;
    this.setState(
      {
        current: current_log,
        currentDoc: this.props.data.logs[current_log],
      },
      async () => {
        await this.loadDocument();
      }
    );
  }

  handlePrev() {
    const current_log = this.state.current - 1;
    this.setState(
      {
        current: current_log,
        currentDoc: this.props.data.logs[current_log],
      },
      async () => {
        await this.loadDocument();
      }
    );
  }

  setVersion(event: any, value: any) {
    // TODO add interface
    this.setState(
      {
        current: value,
        currentDoc: this.props.data.logs[value],
      },
      async () => {
        await this.loadDocument();
      }
    );
  }

  handlePlay(e: any) {
    // TODO add interface
    const { current } = this.state;
    const { data } = this.props;
    if (current < data.logs.length - 1) {
      this.setState(
        {
          isPlaying: e,
        },
        () => {
          this.playLogs();
        }
      );
    } else if (current === data.logs.length - 1) {
      this.setState(
        {
          isPlaying: true,
          current: 1,
          currentDoc: this.props.data.logs[1],
        },
        () => {
          this.playLogs();
        }
      );
    }
  }

  playLogs() {
    const { current, isPlaying, sleepTime } = this.state;
    const { data } = this.props;
    if (isPlaying === false || current >= data.logs.length - 1) return;
    this.handleNext();
    setTimeout(() => {
      this.playLogs();
    }, sleepTime);
  }

  async loadDocument() {
    const { current } = this.state;
    const oldId = this.props.data.logs[current - 1].id;
    const newId = this.props.data.logs[current].id;
    try {
      const showDiff = await showTextDiff(oldId, newId);
      this.setState({
        content: showDiff.data.response,
      });
    } catch (err) {}
  }

  render() {
    const { data } = this.props;
    const { content, current, isPlaying } = this.state;
    return (
      <Main>
        {data && (
          <Grid container sx={{ height: "100vh" }}>
            <Grid item xs={12} md={12} lg={8} xl={8}>
              <Slider
                aria-label="pretto slider"
                defaultValue={1}
                value={this.state.current}
                // getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                onChangeCommitted={this.setVersion}
                step={1}
                marks
                min={1}
                max={data.logs.length - 1}
              />
              <Paper sx={{ p: 2, height: "100%" }}>
                {content && (
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <Box
                sx={{
                  "& > :not(style)": { m: 1 },
                  position: "sticky",
                  top: "4rem",
                }}
              >
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={() => this.handlePrev()}
                  disabled={current <= 1}
                >
                  <ChevronLeftIcon />
                </Fab>
                <Fab
                  aria-label="like"
                  color="secondary"
                  onClick={() => this.handlePlay(!isPlaying)}
                >
                  {data.logs.length - 1 === current ? (
                    <ReplayOutlinedIcon />
                  ) : isPlaying ? (
                    <PauseOutlinedIcon />
                  ) : (
                    <PlayArrowOutlinedIcon />
                  )}
                </Fab>
                <Fab
                  color="primary"
                  aria-label="edit"
                  onClick={() => this.handleNext()}
                  disabled={current >= data.logs.length - 1}
                >
                  <ChevronRightOutlinedIcon />
                </Fab>
                <Fab color="primary" aria-label="edit">
                  <ExpandLessOutlinedIcon />
                </Fab>
                <Fab color="primary" aria-label="edit">
                  <ExpandMoreOutlinedIcon />
                </Fab>
                <Timeline position="right" sx={{ alignItems: "flex-start" }}>
                  <TimelineItem>
                    <TimelineOppositeContent
                      color="text.secondary"
                      sx={{ flex: 0.4 }}
                    >
                      Start
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {dateService.toTimeZone(data.logs[current - 1].timestamp)}
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent
                      color="text.secondary"
                      sx={{ flex: 0.4 }}
                    >
                      Time elapsed
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {dateService.dateDiffInSecs(
                        data.logs[current - 1].timestamp,
                        data.logs[current].timestamp
                      )}
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent
                      color="text.secondary"
                      sx={{ flex: 0.4 }}
                    >
                      End
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      {dateService.toTimeZone(data.logs[current].timestamp)}
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Box>
            </Grid>
          </Grid>
        )}

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.props.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Main>
    );
  }
}

function mapStateToProps(state: any) {
  // TODO add interface
  const { data, isLoading } = state.data;
  return {
    data,
    isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(ShowProgress));
