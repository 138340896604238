import React from "react";
import {
  AppBar,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { withRouter } from "../../../common/withRouter";
import { connect } from "react-redux";
import Main from "../../../elements/public/main";
import authToken from "../../../services/auth-token";
import { validate } from "../../../actions/auth";
import CloseIcon from "@mui/icons-material/Close";
import GradeCard from "../../../elements/graph/circle.grade.card";
// import ScoreCard from "../../elements/graph/circle.score.card";
import SendIcon from "@mui/icons-material/Send";
import WritingProgress from "./Rewind/Dashboard";
import InstCollab from "../../../elements/public/Instructor.FS.Editor";
// eslint-disable-next-line
import InstructorGraph from "../../../components/classes/Instructor.graph";
import { getNetSubmissionId } from "../../../services/classes.service";
import {
  evaluateSubmissionAction,
  finalizeSubmissionAction,
  getSubmissionLogsAction,
  getSubmissionDataAction,
  getTimeMetrics,
  gradeSubmissionAction,
  reopenSubmissionAction,
} from "../../../store/ReadAssignment/actions";
import { apiIds } from "../../../store/ReadAssignment/constants";
import TimeMetrics from "./TimeMetrics";
import { isAfter, parseISO } from "date-fns";
import GradeSubmission from "./Submission/GradeSubmisssion";
import { setSnackbarMessage } from "../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../store/UIActions/Snackbar/model";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import Feedback from "./Feedback/Dashboard";
import GradeHistory from "./Submission/GradeHistory";
import { TrackChangeMethods, loading, tooltips } from "./constants";
import CustomizedSteppers from "./ActivityTimeLine/Dashboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  // TODO look at this later
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

// TODO add interface
class ReadAssignments extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.configureEditor = this.configureEditor.bind(this);
    this.areAssignmentLogsEmpty = this.areAssignmentLogsEmpty.bind(this);
    this.handleClosefile = this.handleClosefile.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleOpenCollab = this.handleOpenCollab.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseCollab = this.handleCloseCollab.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSetToRevise = this.handleSetToRevise.bind(this);
    this.handleSetToFinal = this.handleSetToFinal.bind(this);
    this.reloadDocument = this.reloadDocument.bind(this);
    this.handleSetGrade = this.handleSetGrade.bind(this);
    this.handleOpenGraph = this.handleOpenGraph.bind(this);
    this.handleCloseGraph = this.handleCloseGraph.bind(this);
    this.handleGraphChange = this.handleGraphChange.bind(this);
    this.handleSetOpenSubmission = this.handleSetOpenSubmission.bind(this);
    this.handleOpenFeedBack = this.handleOpenFeedBack.bind(this);
    this.handleCloseFeedBack = this.handleCloseFeedBack.bind(this);
    this.handleOpenGradesHistory = this.handleOpenGradesHistory.bind(this);
    this.handleCloseGradesHistory = this.handleCloseGradesHistory.bind(this);
    this.handleTimeLineEventClick = this.handleTimeLineEventClick.bind(this);
    this.TRChangeState = this.TRChangeState.bind(this);
    this.TRShow = this.TRShow.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.isAssignmentSubmittedAfterDueDate =
      this.isAssignmentSubmittedAfterDueDate.bind(this);

    this.state = {
      id: this.props.router.params.id,
      fileId: this.props.router.params.id,
      EDITOR_URL: process.env.REACT_APP_INS_EDITOR_URL,
      hostUrl: process.env.REACT_APP_API_URL,
      open: false,
      openFScollabora: false,
      classId: "",
      studentId: "",
      studentIndex: null,
      numberOfStudent: null,
      assignmentId: "",
      grade: "",
      target: "collabora-online-viewer",
      opengraph: false,
      openFinalizeDialog: false,
      openFeedback: false,
      openGradeHistory: false,
      branch: "Ongoing",
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    this.props.dispatch(validate());
    // api call to get time metrics
    // api call to get submission data
    this.props.dispatch(getTimeMetrics(id));
    this.props.dispatch(getSubmissionLogsAction(id));
    await this.props.dispatch(getSubmissionDataAction(id));

    this.setState({
      classId: this.props.assignment.submissionData.classId,
      studentId: this.props.assignment.submissionData.studentId,
      studentIndex: this.props.assignment.submissionData.currentStudentIndex,
      numberOfStudent: this.props.assignment.submissionData.numberOfStudents,
      assignmentId: this.props.assignment.submissionData.assignmentId,
      status: this.props.assignment.submissionData.status,
      lastSubmission: this.props.assignment.submissionData.lastSubmission,
      assignmentDueDate: this.props.assignment.submissionData.assignmentDuedate,
      lastEvaluation: this.props.assignment.submissionData.scoresHistory.at(-1), // get last item
      grade:
        this.props.assignment.submissionData.grade !== 0
          ? this.props.assignment.submissionData.grade
          : 0,
      graph: "0",
    });
    this.reloadDocument();
    const obj = { MessageId: "Host_PostmessageReady" };
    window.addEventListener(
      "message",
      (event) => {
        if (this.state.EDITOR_URL.includes(event.origin)) {
          try {
            JSON.parse(event.data);
          } catch (e) {
            return;
          }
          if (
            JSON.parse(event.data).MessageId === "App_LoadingStatus" &&
            JSON.parse(event.data).Values.Status === "Frame_Ready"
          ) {
            window.frames[0].postMessage(JSON.stringify(obj), "*");
          } else if (
            JSON.parse(event.data).MessageId === "App_LoadingStatus" &&
            JSON.parse(event.data).Values.Status === "Document_Loaded"
          ) {
            this.configureEditor();
            this.TRChangeState();
            this.TRShow();
          } else if (
            JSON.parse(event.data).MessageId === "Clicked_Button" &&
            JSON.parse(event.data).Values.Id === "FS_BTN"
          ) {
            this.handleClosefile();
            this.handleOpenCollab();
          }
        }
        // …
      },
      false
    );
  }

  // TODO add interface
  handleGraphChange(e: any) {
    this.setState({
      graph: e.target.name,
    });
  }

  TRChangeState(status: boolean = true) {
    const obj = {
      MessageId: "CallPythonScript",
      SendTime: Date.now(),
      ScriptFile: TrackChangeMethods.FILENAME,
      Function: status
        ? TrackChangeMethods.ACTIVATE
        : TrackChangeMethods.DEACTIVATE,
      Values: {},
    };
    window.frames[0].postMessage(JSON.stringify(obj), "*");
    return;
  }

  TRShow() {
    const obj = {
      MessageId: "CallPythonScript",
      SendTime: Date.now(),
      ScriptFile: TrackChangeMethods.FILENAME,
      Function: TrackChangeMethods.SHOW,
      Values: {},
    };
    window.frames[0].postMessage(JSON.stringify(obj), "*");
    return;
  }

  handleSave() {
    const obj = {
      MessageId: "Action_Save",
      Values: {
        DontTerminateEdit: true,
        DontSaveIfUnmodified: true,
        Notify: true,
      },
    };

    window.frames[0].postMessage(JSON.stringify(obj), "*");

    return;
  }

  async handleSetToRevise() {
    const { id } = this.state;
    try {
      await this.props.dispatch(evaluateSubmissionAction(id));
      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Assignment submitted for evaluation! The metrics will be available in a few minutes.`,
        })
      );
      this.reloadDocument();
    } catch (err) {
      alert("error occurred");
    }
  }

  async handleSetToFinal() {
    const { id } = this.state;
    try {
      // const setFinal = await setToFinal(id);
      // this.setState({ status: setFinal.data });
      await this.props.dispatch(finalizeSubmissionAction(id));
      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Assignment submitted as a final draft!`,
        })
      );
      this.reloadDocument();
    } catch (err) {
      alert("error occurred");
    }
  }

  configureEditor() {
    const add_cfs = {
      MessageId: "Insert_Button",
      Values: {
        id: "FS_BTN",
        hint: "Fullscreen",
        imgurl: window.location.origin + "/fs.svg",
        mobile: false,
        label: "Full screen",
        insertBefore: "file",
      },
    };
    window.frames[0].postMessage(JSON.stringify(add_cfs), "*");
  }

  handleClosefile() {
    const obj = { MessageId: "Action_Close", Values: null };
    window.frames[0].postMessage(JSON.stringify(obj), "*");
    return;
  }

  async handleSetGrade() {
    const { id, grade } = this.state;

    if (grade < 0) {
      alert("Invalid grade.");
      return;
    }
    try {
      await this.props.dispatch(
        gradeSubmissionAction(id, grade === "" ? -1 : grade)
      );
      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Grade updated!`,
        })
      );
      this.reloadDocument();
    } catch (err) {
      alert("error occured");
    }
  }

  // TODO add interface
  async handleMove(direction: number) {
    const { classId, assignmentId, studentIndex } = this.state;

    try {
      const getSubmissionId = await getNetSubmissionId(
        classId,
        studentIndex,
        assignmentId,
        direction
      );
      window.location.replace("/readassignment/" + getSubmissionId.data);
    } catch (err) {
      alert("No more submissions for this assignment have been found.");
    }
  }

  reloadDocument() {
    // TODO add interface
    const formformExists: any = document.getElementById(
      "collabora-submit-form"
    );

    if (formformExists) {
      formformExists.submit();
    }
  }

  // TODO add interface
  onChange(e: any) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleCloseCollab() {
    this.setState(
      {
        openFScollabora: false,
        target: "collabora-online-viewer",
      },
      () => {
        this.reloadDocument();
      }
    );
  }
  handleOpenCollab() {
    this.handleClosefile();
    this.setState({
      openFScollabora: true,
      target: "collabora-online-viewer-fs",
    });
  }
  handleOpenGraph() {
    this.setState({
      opengraph: true,
    });
  }

  async handleSetOpenSubmission() {
    await this.props.dispatch(reopenSubmissionAction(this.state.id));
    this.props.dispatch(
      setSnackbarMessage({
        type: AlertTypes.SUCCESS,
        showSnackBar: true,
        message: `Assignment reopened!`,
      })
    );
  }
  handleCloseGraph() {
    this.setState({
      opengraph: false,
    });
  }
  isLoading() {
    return this.props.loader.loadingIds.includes(apiIds.getSubmissionData);
  }

  showLoader() {
    const isLoader = this.props.loader.loadingIds.some((c: string) =>
      loading.includes(c)
    );
    return isLoader;
  }

  isTimeMetricsLoading() {
    return this.props.loader.loadingIds.includes(apiIds.getTimeMetrics);
  }
  isAssignmentSubmittedAfterDueDate() {
    return isAfter(
      parseISO(this.state.lastSubmission),
      parseISO(this.state.assignmentDueDate)
    );
  }
  areAssignmentLogsEmpty() {
    return this.props.assignment.submissionData.submissionLog.logs.length === 0;
  }

  handleOpenFeedBack() {
    this.setState({ openFeedback: true });
  }

  handleCloseFeedBack() {
    this.setState({ openFeedback: false });
  }

  handleOpenGradesHistory() {
    this.setState({ openGradeHistory: true });
    console.debug("test");
  }

  handleCloseGradesHistory() {
    this.setState({ openGradeHistory: false });
  }

  handleTimeLineEventClick(fileId: string, branch: string) {
    if (fileId === "") {
      this.setState({ fileId: this.state.id, branch: "Main" }, () =>
        this.reloadDocument()
      );
    } else {
      this.setState({ fileId, branch }, () => this.reloadDocument());
    }
  }

  render() {
    const { id, target, fileId } = this.state;
    return (
      <Main>
        <Grid container spacing={3}>
          <Grid
            item
            md={3}
            lg={2}
            display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
            sx={{ mt: 3 }}
          >
            {!this.isLoading() && this.props.assignment.submissionData && (
              <Grid item>
                <GradeSubmission
                  assignment={this.props.assignment.submissionData}
                  handleMove={this.handleMove}
                  handleSetToRevise={this.handleSetToRevise}
                  handleSetGrade={this.handleSetGrade}
                  handleSetToFinal={() => {
                    this.setState((prevState: any) => {
                      return {
                        ...prevState,
                        openFinalizeDialog: true,
                      };
                    });
                  }}
                  onGradeChange={this.onChange}
                  onSetReOpen={this.handleSetOpenSubmission}
                  loadingState={this.props.loader}
                  grade={this.state.grade}
                  branch={this.state.branch}
                />
                {/* <GradeCard
                  score={this.props.assignment.submissionData.sincerity}
                  type={0}
                  title="Sincerity"
                  sx={{ mt: 4 }}
                /> */}
                <GradeCard
                  score={this.props.assignment.submissionData.effort}
                  tooltip={tooltips.EFFORT_TOOLTIP}
                  type={1}
                  title="Effort"
                  sx={{ mt: 4 }}
                />
                <GradeCard
                  score={this.props.assignment.submissionData.revision}
                  tooltip={tooltips.REVISION_TOOLTIP}
                  type={1}
                  title="Revision"
                  sx={{ mt: 4 }}
                />
                {/* <ScoreCard score={15} title="Unorginal Language" /> */}

                <TimeMetrics
                  active={this.props.assignment.timeMetrics?.active}
                  think={this.props.assignment.timeMetrics?.think}
                  real={this.props.assignment.timeMetrics?.real}
                  status={this.state?.status}
                  isLoading={this.isTimeMetricsLoading()}
                  isLateSubmission={this.isAssignmentSubmittedAfterDueDate()}
                  title="Time metrics"
                  lastSubmissionDate={this.state.lastSubmission}
                  lastEvaluationDate={this.state.lastEvaluation?.timestamp}
                  classRank={this.props.assignment.timeMetrics.classRank}
                  classNumMembers={
                    this.props.assignment.timeMetrics.classNumMembers
                  }
                />

                <Tooltip title={tooltips.ANALYTICS} placement="right">
                  <Card sx={{ mt: 1 }}>
                    <CardHeader
                      sx={{ height: 50, mt: 0 }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          src="/analytics.png"
                          variant="rounded"
                        ></Avatar>
                      }
                      title={
                        <Button
                          fullWidth
                          variant="text"
                          sx={{ mt: 1 }}
                          onClick={() => this.handleOpenGraph()}
                        >
                          Analytics
                        </Button>
                      }
                    />
                  </Card>
                </Tooltip>
                <Tooltip title={tooltips.REWIND} placement="right">
                  <Card sx={{ mt: 1 }}>
                    <CardHeader
                      sx={{ height: 50, mt: 0 }}
                      avatar={
                        <Avatar aria-label="recipe" src="/logo4.png"></Avatar>
                      }
                      title={
                        <Button
                          fullWidth
                          variant="text"
                          sx={{ mt: 1 }}
                          onClick={() => this.handleOpen()}
                          disabled={this.areAssignmentLogsEmpty()}
                        >
                          Rewind Essay
                        </Button>
                      }
                    />
                  </Card>
                </Tooltip>
                <Tooltip
                  title={tooltips.FEEDBACK}
                  placement="right"
                  disableInteractive
                >
                  <Card sx={{ mt: 1 }}>
                    <CardHeader
                      sx={{ height: 50, mt: 0 }}
                      avatar={
                        <Avatar sx={{ bgcolor: "blueviolet" }}>
                          <FeedbackIcon />
                        </Avatar>
                      }
                      title={
                        <Button
                          fullWidth
                          variant="text"
                          sx={{ mt: 1 }}
                          onClick={() => this.handleOpenFeedBack()}
                        >
                          Feedback
                        </Button>
                      }
                    />
                  </Card>
                </Tooltip>
                <Feedback
                  open={this.state.openFeedback}
                  onClose={this.handleCloseFeedBack}
                  submissionId={id}
                ></Feedback>
                <Tooltip title={tooltips.SCORE} placement="right">
                  <Card sx={{ mt: 1 }}>
                    <CardHeader
                      sx={{ height: 50, mt: 0 }}
                      avatar={
                        <Avatar sx={{ bgcolor: "darkturquoise" }}>
                          <HistoryEduRoundedIcon />
                        </Avatar>
                      }
                      title={
                        <Button
                          fullWidth
                          variant="text"
                          sx={{ mt: 1 }}
                          onClick={() => this.handleOpenGradesHistory()}
                        >
                          Score History
                        </Button>
                      }
                    />
                  </Card>
                </Tooltip>
                <GradeHistory
                  open={this.state.openGradeHistory}
                  onClose={this.handleCloseGradesHistory}
                ></GradeHistory>
              </Grid>
            )}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "300px" }}>
              <GradeRadar />
            </Grid> */}
          </Grid>
          <Grid item sm={12} md={9} lg={10}>
            {id && (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CustomizedSteppers
                    deadlines={this.props.assignment.submissionData.deadlines}
                    submissions={
                      this.props.assignment.submissionData.deadlineSubmissions
                    }
                    startDate={
                      this.props.assignment.submissionData.assignmentStartDate
                    }
                    createdDate={
                      this.props.assignment.submissionData.createDate
                    }
                    timezone={
                      this.props.assignment.submissionData.classTimezone
                    }
                    branch={this.state.branch}
                    handleClick={this.handleTimeLineEventClick}
                    mainfile={id}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ height: "88vh" }}
                >
                  <>
                    <form
                      action={this.state.EDITOR_URL + fileId}
                      encType="multipart/form-data"
                      method="post"
                      target={target}
                      id="collabora-submit-form"
                    >
                      <input
                        name="access_token"
                        value={authToken()}
                        type="hidden"
                        id="access-token"
                      />
                      <input
                        name="css_variables"
                        value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
                        type="hidden"
                      />

                      <input
                        name="ui_defaults"
                        value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
                        type="hidden"
                      />
                    </form>
                    <Container
                      maxWidth={false}
                      sx={{
                        contain: "layout",
                        height: ["inherit ", "-webkit-fill-available"],
                      }}
                    >
                      <iframe
                        title="Collabora Online Viewer"
                        id="collabora-online-viewer"
                        name="collabora-online-viewer"
                        allowFullScreen={true}
                      ></iframe>
                    </Container>
                  </>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Dialog
          fullScreen
          open={this.state.open}
          // onClose={this.handleClose}
          // TODO look later
          // @ts-ignore
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Rewind Essay
              </Typography>
            </Toolbar>
          </AppBar>
          {this.props.loader.loadingIds.includes(apiIds.getSubmissionLogs) ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={this.props.loader.loadingIds.includes(
                apiIds.getSubmissionLogs
              )}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <WritingProgress />
          )}
        </Dialog>
        <Dialog
          fullScreen
          open={this.state.openFScollabora}
          // TODO look later
          // @ts-ignore
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseCollab}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Full Screen Panel
              </Typography>
            </Toolbar>
          </AppBar>
          <Container
            maxWidth={false}
            sx={{
              contain: "layout",
              height: ["inherit ", "-webkit-fill-available"],
            }}
          >
            <InstCollab />
          </Container>
        </Dialog>
        <Dialog
          fullScreen
          open={this.state.opengraph}
          // TODO look later
          // @ts-ignore
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleCloseGraph}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, color: "black" }}
                variant="h6"
                component="div"
              >
                Analytics
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
                {["EssayMap", "HeatMap", "WordChange"].map((page, index) => (
                  // TODO name prop is the reason why we have to ts ignore
                  // @ts-ignore
                  <Button
                    key={page}
                    onClick={(e: any) => this.handleGraphChange(e)}
                    sx={{
                      my: 2,
                      mx: 2,
                      color: "black",
                      display: "block",
                      textTransform: "none",
                    }}
                    name={index}
                    variant="contained"
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
          <InstructorGraph graph={this.state.graph} subid={this.state.id} />
        </Dialog>

        <Dialog
          onClose={() => {
            this.setState((prevState: any) => {
              return {
                ...prevState,
                openFinalizeDialog: false,
              };
            });
          }}
          open={this.state.openFinalizeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            Finalize submission
            <Divider />{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to <b>Finalize</b> the submission? This
                will turn the assignment into a final submission and prevent the
                student from writing more.
              </DialogContentText>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.props.loader.loadingIds.includes(
                apiIds.finalizeSubmission
              )}
              onClick={() => {
                this.setState((prevState: any) => {
                  return {
                    ...prevState,
                    openFinalizeDialog: false,
                  };
                });
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await this.handleSetToFinal();
                this.props.dispatch(
                  setSnackbarMessage({
                    type: AlertTypes.SUCCESS,
                    showSnackBar: true,
                    message: `Assignment submitted as a final draft!`,
                  })
                );
                this.setState((prevState: any) => {
                  return {
                    ...prevState,
                    openFinalizeDialog: false,
                  };
                });
              }}
              autoFocus
              disabled={this.props.loader.loadingIds.includes(
                apiIds.finalizeSubmission
              )}
              startIcon={
                <SendIcon
                  sx={{
                    color: "black",
                  }}
                />
              }
            >
              Finalize
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.showLoader()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Main>
    );
  }
}

function mapStateToProps(state: any) {
  const { data, assignment, loader } = state;
  return {
    data,
    assignment,
    loader,
  };
}

export default withRouter(connect(mapStateToProps)(ReadAssignments));
