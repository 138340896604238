import React, { useEffect, useState } from "react";
import { PanelProps } from "../utils/properties";
import StudentDetails from "./Components/StudentDetails";
import GradeCard from "../../../elements/graph/circle.grade.card";
import { tooltips } from "../../instructor/ReadAssignment/constants";
import TimeMetric from "./Components/TimeMetric";

import { Card, CardHeader, Avatar, Button } from "@mui/material";
import AnalyticsDashboard from "./Analytics/Dashboard";
import RewindDialog from "./Components/RewindDialog";
import { useSelector } from "react-redux";
import { Log } from "../../../store/ReadAssignment/model";

const Panel: React.FC<PanelProps> = ({ data, timeMetric, id }) => {
  const logs: Log[] = useSelector(
    (state: any) => state.assignment.submissionData.submissionLog.logs
  ); 
  const [open, setOpen] = useState(false);
  const [rewindOpen, setRewindOpen] = useState<boolean>(false);
  const [rewindDisabled, serRewindDisabled] = useState<boolean>(true);
  const handleOpenGraph = () => setOpen(true);
  const handleCloseGraph = () => setOpen(false);

  const handleOpenRewind = () => setRewindOpen(true);
  const handleCloseRewind = () => setRewindOpen(false);

  useEffect(()=>{
    serRewindDisabled(logs.length === 0);
  },[logs]);

    return (
    <>
      <StudentDetails data={data} />
      <GradeCard
        score={data.effort}
        tooltip={tooltips.EFFORT_TOOLTIP}
        type={1}
        title="Effort"
        sx={{ mt: 4 }}
      />
      <GradeCard
        score={data.revision}
        tooltip={tooltips.REVISION_TOOLTIP}
        type={1}
        title="Revision"
        sx={{ mt: 4 }}
      />
      <TimeMetric metrics={timeMetric} />
      <Card sx={{ mt: 1 }}>
        <CardHeader
          sx={{ height: 50, mt: 0 }}
          avatar={
            <Avatar
              aria-label="recipe"
              src="/analytics.png"
              variant="rounded"
            ></Avatar>
          }
          title={
            <Button
              fullWidth
              variant="text"
              sx={{ mt: 1 }}
              onClick={() => handleOpenGraph()}
            >
              Analytics
            </Button>
          }
        />
      </Card>
      <Card sx={{ mt: 1 }}>
        <CardHeader
          sx={{ height: 50, mt: 0 }}
          avatar={<Avatar aria-label="recipe" src="/logo4.png"></Avatar>}
          title={
            <Button
              fullWidth
              variant="text"
              sx={{ mt: 1 }}
              onClick={() => handleOpenRewind()}
              disabled={rewindDisabled }
            >
              Rewind Essay
            </Button>
          }
        />
      </Card>
      <AnalyticsDashboard open={open} handleClose={handleCloseGraph} id={id} />
      <RewindDialog rewindOpen={rewindOpen} handleClose={handleCloseRewind} />
    </>
  );
};

export default Panel;
