import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { download, getClassDetail } from "../utils/service";
import { ClassDetails } from "../utils/model";
import Main from "../../../elements/public/main";
import { validate } from "../../../actions/auth";
import { Box, Tab, Tabs, Grid } from "@mui/material";
import Details from "./Details/Details";
import StudentsList from "./Students/StudentsList";
import Assignments from "./Assignments/Assignments";

const CourseDetail: React.FC = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const userRoles: string[] | undefined = useSelector<
    any,
    string[] | undefined
  >((state) => state.auth.user?.roles);

  const [data, setData] = useState<ClassDetails>();
  const [state, setState] = useState({
    value: 0,
  });
  // Check if the user is not logged in or roles are not available
  useEffect(() => {
    // Dispatch the validate action when the component mounts
    // @ts-ignore
    dispatch(validate());

    // Fetch data asynchronously and update the state
    const fetchData = async () => {
      try {
        // @ts-ignore
        const response = await getClassDetail(id);
        // @ts-ignore
        setData(response.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [dispatch,id]);

  
  // @ts-ignore
  const isEduCoordinator: boolean = userRoles.includes(
    "ROLE_EDUCATION_COORDINATOR"
  );
  const DETAILS = 0;
  const STUDENTS = 1;
  const ASSIGNMENTS = 2;
  const GETFILE = 3;

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  if (!isEduCoordinator) {
    return (
      <Main>
        <div>You don't have access to this page.</div>
      </Main>
    );
  }
  return (
    <Main>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={state.value}
            onChange={(event: any, newValue: any) => {
              if (newValue === GETFILE && id!==undefined) {
                download(id);
                return;
              }
              setState({
                ...state,
                value: newValue,
              });
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Details" {...a11yProps(DETAILS)} />
            <Tab label="Students" {...a11yProps(STUDENTS)} />
            <Tab label="Assignments" {...a11yProps(ASSIGNMENTS)} />
            <Tab label="Download Excel" {...a11yProps(GETFILE)} />
          </Tabs>
        </Box>
        <Grid container spacing={2} my={2}>
          <Grid item xs={12}>
            {state.value === DETAILS && data !== undefined && (
              <>
                <Details
                  className={data.className}
                  subjectArea={data.subjectArea}
                  studentLevel={data.studentLevel}
                  startDate={data.startDate.toString()}
                  endDate={data.endDate.toString()}
                  classTimeZone={data.classTimeZone}
                />
              </>
            )}
            {state.value === STUDENTS && data !== undefined && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StudentsList
                      students={data.students}
                      assignments={data.assignments}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {state.value === ASSIGNMENTS && data !== undefined && (
              <Assignments
                assignments={data.assignments}
                students={data.students}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default CourseDetail;
