export type TabType =
  | "Heatmap"
  | "Hourly Activity"
  | "Words Changed"
  | "Assignment Activity"
  | "Class Total Time"
  | "Student Total Time"
  | "Word Change"
  | "EssayMap"
  | "Time Averages"
  | "WPM Averages";

export const assignmentTabs: TabType[] = [
  "Heatmap",
  "Hourly Activity",
  "Words Changed",
];

export const studentTabs: TabType[] = [
  "Student Total Time",
  "Word Change",
  "Words Changed",
  "Hourly Activity",
  "Heatmap",
  "EssayMap",
];

export const classTabs: TabType[] = [
  "Class Total Time",
  "Time Averages",
  "WPM Averages",
  "Words Changed",
  "Assignment Activity",
  "Hourly Activity",
  "Heatmap",
];

interface DataItem {
  x: number;
  y: number;
  size: number;
  pattern: string;
}

export interface DataNode {
  id: string;
  data: DataItem[];
}

export interface GraphsProps {
  HeatmapProps: any | null;
  lineGraphProps: any | null;
  TimeSeriesProps: any | null;
  CompariosnProps: any | null;
  WordChangeProps: any | null;
  EssayMapProps: string | null;
  WPMProps: any[] | null;
}

export const initGraphProps: GraphsProps = {
  HeatmapProps: null,
  lineGraphProps: null,
  TimeSeriesProps: null,
  CompariosnProps: null,
  WordChangeProps: null,
  EssayMapProps: null,
  WPMProps: null,
};

export interface StudentAnalyctic {
  studentId: string;
  studentName: string;
}

export interface DateRange {
  Heatmap: number[] | undefined;
  HeatMapMinMax: number[] | undefined;
  LineGraph: number[] | undefined;
  LineGraphMinMax: number[] | undefined;
  TimeSeries: number[] | undefined;
  TimeSeriesMinMax: number[] | undefined;
  Comparison: number[] | undefined;
  ComparisonMinMax: number[] | undefined;
}

export const initDateRange: DateRange = {
  Heatmap: undefined,
  LineGraph: undefined,
  TimeSeries: undefined,
  HeatMapMinMax: undefined,
  LineGraphMinMax: undefined,
  TimeSeriesMinMax: undefined,
  Comparison: undefined,
  ComparisonMinMax: undefined,
};

export const fillPatterns = [
  {
    id: "dots-small",
    type: "patternDots",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 2,
    padding: 1,
    stagger: true,
  },

  {
    id: "lines-thin",
    type: "patternLines",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    rotation: -45,
    lineWidth: 2,
    spacing: 6,
  },
  {
    id: "squares",
    type: "patternSquares",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 6,
    padding: 2,
    stagger: false,
  },
  {
    id: "lines-thick-straight",
    type: "patternLines",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    rotation: 0,
    lineWidth: 8,
    spacing: 10,
  },
  {
    id: "dots-large",
    type: "patternDots",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 6,
    padding: 1,
    stagger: true,
  },
  {
    id: "lines-thin-straight",
    type: "patternLines",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    rotation: 0,
    lineWidth: 2,
    spacing: 6,
  },
  {
    id: "stagger-squares",
    type: "patternSquares",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 6,
    padding: 2,
    stagger: true,
  },

  {
    id: "lines-thick",
    type: "patternLines",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    rotation: 45,
    lineWidth: 8,
    spacing: 10,
  },
];

export const fillEssayMapPatterns = [
  {
    id: "dots-small",
    type: "patternDots",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 2,
    padding: 1,
    stagger: true,
  },

  {
    id: "lines-thin",
    type: "patternLines",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    rotation: -45,
    lineWidth: 2,
    spacing: 6,
  },
  {
    id: "squares",
    type: "patternSquares",
    background: "inherit",
    color: "rgba(255, 255, 255, 0.3)",
    size: 6,
    padding: 2,
    stagger: false,
  },
];

type TooltipProps = {
  [key in TabType]: string;
};

export const tooltipsText: TooltipProps = {
  Heatmap:
    "The Heatmap shows the hourly activity on the assignment. The hours of the day are the columns and the days are the rows.",
  "Hourly Activity":
    "Hourly Activity shows the total number of changes made each hour of the day with the different lines representing different days.",
  "Words Changed":
    "The Words Changed graph shows the total amount of words changed to all assignments every eight hours.",
  "Assignment Activity":
    "The Assignment Activity graph represents activity for each assignment on different lines. Each point represents an eight hour period.",
  "Class Total Time":
    "The Class Total Time graph shows the total amount of time all students spent on all different assignments, represented as a pie chart.",
  "Word Change":
    "The word change graph shows the total change word count for the assignment each minute while the student is writing.",
  EssayMap:
    "The EssayMaps shows all on the assignment by the student. Time is represented on the x-axis, and the location in the text is represented on the y-axis.",
  "Time Averages":
    "The Time Averages graph shows the distribution of Total Writing Time for each student for each assignment. The centerline of each boxplot represents the median. The whiskers of each boxplot represent the 10th and 90th percentile.",
  "Student Total Time":
    "The Student Total Time graph shows the total amount of time the student spent on all different assignments, represented as a pie chart.",
  "WPM Averages": "The WPM averages graph shows the distribution of words per minute for each student for each assignment. The centerline of each boxplot represents the median. The whiskers of each boxplot represent the 10th and 90th percentile.",
};
