import { parseISO, format, differenceInSeconds } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export function toTimeZone(date: string) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(
    utcToZonedTime(parseISO(date), timeZone),
    "yyyy-MM-dd kk:mm:ss a"
  );
}
export function toCustomTimeZone(timezone: string, date: string) {
  return format(
    utcToZonedTime(parseISO(date), timezone),
    "yyyy-MM-dd kk:mm:ss a"
  );
}
export function dateDiffInSecs(oldDate: string, newDate: string) {
  const o = parseISO(oldDate);
  const n = parseISO(newDate);
  const diff = differenceInSeconds(n, o);
  const days = Math.floor(diff / (3600 * 24));
  const hours = Math.floor((diff % (3600 * 24)) / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const remainingSeconds = diff % 60;

  if (diff < 3600) {
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  } else if (diff < 86400) {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }
  return `${days}d ${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
}

export function toTimeZoneSimple(date: string) {
  const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(
    utcToZonedTime(parseISO(date), timeZone),
    "yyyy-MM-dd kk:mm"
  );
}

export function tocustomTimeZoneSimple(date: string, tz:string) {
  return format(
    utcToZonedTime(parseISO(date), tz),
    "yyyy-MM-dd kk:mm"
  );
}

export function toDateString(date: string) {
  return format(new Date(date), "yyyy-MM-dd kk:mm");
}
