import React, { useEffect, useState } from "react";
import { validate } from "../../../../actions/auth";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Main from "../../../../elements/public/main";
import {
  NameSearchProps,
  SearchSubmissionRequest,
  SearchSubmissionResponse,
  initSearchState,
} from "./models";
import { getNames, getSubmissions } from "./services";
import { Link } from "react-router-dom";

const SearchSubmission = () => {
  const [nameList, setNameList] = useState<NameSearchProps[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [searchReq, setSearchReq] =
    useState<SearchSubmissionRequest>(initSearchState);

  const [searchRes, setSearchRes] = useState<SearchSubmissionResponse[]>([]);

  useEffect(() => {
    const searchName = async () => {
      if (inputValue?.length >= 3) {
        const response = await getNames(inputValue);
        if (response.status === 200) {
          setNameList(response.data);
        }
      }
    };
    searchName();
  }, [inputValue]);

  const dispatch = useDispatch();
  // @ts-ignore
  dispatch(validate());

  const handleSearch = async (e: any) => {
    e.preventDefault();
    setSearchRes([]);
    try {
      const response = await getSubmissions(searchReq);
      if(response.status === 200){
        setSearchRes(response.data);
        return
      }
      throw new Error("Fail to load the submissions");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Main>
      {" "}
      <form onSubmit={handleSearch}>
        <Grid container component={Paper} sx={{ p: 3 }}>
          <Grid item xs={12} lg={6}>
            <Grid>
              <Autocomplete
                options={nameList}
                getOptionLabel={(option) => option.name} // Assuming each option has a 'name' property
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(event, newInputValue) => {
                  setSearchReq({
                    ...searchReq,
                    userId: newInputValue ? newInputValue.id : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Name"
                    variant="standard"
                    required
                  />
                )}
              />
            </Grid>
            <Grid>
              <TextField
                // required={allValuesEmpty(searchData)}
                id="enrollmentKey"
                name="enrollmentKey"
                label="Enrollment Key"
                fullWidth
                variant="standard"
                margin="normal"
                onChange={(e) => {
                  setSearchReq({ ...searchReq, enrollmentKey: e.target.value });
                }}
                value={searchReq.enrollmentKey}
              />
            </Grid>
            <Grid>
              <Button type="submit" fullWidth>
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </form>
      <Grid container sx={{ p: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Class</TableCell>
              <TableCell>Instructor</TableCell>
              <TableCell>Assignment Title</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchRes.map((e, i) => (
              <TableRow>
                <TableCell>{e.className}</TableCell>
                <TableCell>{e.instructorName}</TableCell>
                <TableCell>{e.title}</TableCell>
                <TableCell>
                <Button component={Link} to={`/admin/fixtext/${e.submissionId}`} variant="contained" target="blank">Open</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Main>
  );
};

export default SearchSubmission;
