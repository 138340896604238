import React from "react";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from "@mui/icons-material/School";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupportIcon from '@mui/icons-material/Support';
import { setSupportOpen } from "../../store/UIActions/Support/actions";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

export const UsersMenu = [
  { id: 100, label: "Home", link: "/", icon: <HomeOutlinedIcon /> },
  {
    id: 101,
    label: "Profile",
    link: "/Profile",
    icon: <PermIdentityOutlinedIcon />,
  },
  { id: 102, label: "Logout", link: "/Logout", icon: <LogoutOutlinedIcon /> },
];

export const GuestUsersMenu = [
  { id: 200, label: "Home", link: "/", icon: <HomeOutlinedIcon /> },
  { id: 201, label: "Login", link: "/Login", icon: <LoginOutlinedIcon /> },
  {
    id: 202,
    label: "Sign up",
    link: "/register",
    icon: <PersonAddAltOutlinedIcon />,
  },
];

export const InstUserMenu = [
  // { id: 300, label: "Add new Class", link: "RegisterClass", icon: <MenuBookOutlinedIcon /> },
  {
    id: 300,
    label: "My classes (Instructor)",
    link: "/classes",
    icon: <MenuBookOutlinedIcon />,
  },
];

export const StudentUserMenu = [
  {
    id: 400,
    label: "My classes (Student)",
    link: "/enroll",
    icon: <AppRegistrationOutlinedIcon />,
  },
];

export const StudentGuideMenu = [
  {
    id: 500,
    label: "Student guide",
    link: "/studentguide",
    icon: <HelpOutlineOutlinedIcon />,
  },
];

export const VideoGuideMenu = [
  {
    id: 550,
    label: "Video guide",
    link: "/videoguide",
    icon: <VideoLibraryIcon />,
  },
];

export const InstGuideMenu = [
  {
    id: 501,
    label: "Instructor guide",
    link: "/instructorguide",
    icon: <InfoIcon />,
  },
];

export const EducationCoordinatorMenu = [
  {
    id: 601,
    label: "All classes",
    link: "/educoordinator/courselist",
    icon: <SchoolIcon />,
  },
];

export const AdminMenu = [
  {
    id: 701,
    label: "Admin panel",
    link: "/admin/dashboard",
    icon: <AdminPanelSettingsIcon />,
  },
];

export const UserSupportMenu = [
  {
    id: 801,
    label: "Support",
    link: "",
    action: () =>(dispatch:any) => {
      dispatch(setSupportOpen());
    },
    icon: <SupportIcon />,
  },
];
