import React from "react";
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Snackbar,
  Tooltip,
  Box,
  Grow,
  Button,
  Backdrop,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Main from "../../elements/public/main";
import { setMessage, clearMessage } from "../../actions/message";
import { validate } from "../../actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  enrollClass,
  getMyClasses,
  searchClass,
} from "../../services/classes.service";
import { Class } from "../../services/models/classes.model";

class EnrollInClass extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearchClass = this.handleSearchClass.bind(this);
    this.handleenroll = this.handleenroll.bind(this);
    this.myclassList = this.myclassList.bind(this);

    this.state = {
      loading: false,
      success: false,
      enrollkey: "",
      myclass: null,
      myClasses: null,
      openSB: false,
      openGrow: false,
    };
  }

  async myclassList() {
    this.setState({ loading: true });
    try {
      const myClasses = await getMyClasses();
      this.setState({
        myclasses: myClasses.data,
        loading: false,
      });
    } catch (error: any) {
      this.setState({
        loading: false,
        openSB: true,
      });
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      this.props.dispatch(setMessage(messages));
    }
  }

  async componentDidMount() {
    this.props.dispatch(validate());
    await this.myclassList();
  }

  // TODO add interface
  handleChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleClose() {
    this.setState({
      openSB: false,
    });
  }

  // TODO add interface
  async handleSearchClass(e: any) {
    if (this.state.enrollkey === "") {
      this.props.dispatch(setMessage("Enrollment key is empty!"));
      this.setState({
        openSB: true,
      });
      return;
    }
    const { enrollkey } = this.state;
    this.props.dispatch(clearMessage());
    this.setState({
      myclass: null,
      openGrow: false,
      loading: true,
    });
    try {
      const classSearch = await searchClass(enrollkey);
      this.setState({
        myclass: classSearch.data,
        openGrow: true,
        loading: false,
      });
    } catch (error: any) {
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      this.setState({
        loading: false,
        openSB: true,
      });
      this.myclassList();
      this.props.dispatch(setMessage(messages));
    }
  }

  // TODO add interface
  async handleenroll(e: any) {
    e.preventDefault();
    const classId = this.state.myclass.id;
    this.setState({
      loading: true,
    });

    try {
      const enroll = await enrollClass(classId);
      this.setState({
        myclass: enroll.data,
        openGrow: true,
        loading: false,
      });
      this.myclassList();
    } catch (error: any) {
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      this.setState({
        loading: false,
        openSB: true,
      });

      this.props.dispatch(setMessage(messages));
    }
  }

  render() {
    const { message } = this.props;
    const { openSB, openGrow, myclass, myclasses } = this.state;
    return (
      <Main>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              elevation={24}
              sx={{ my: { xs: 1, md: 3 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={6}
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="enrollkey">Enrollment Key* - get from Instructor</InputLabel>
                    <Input
                      id="enrollkey"
                      name="enrollkey"
                      required
                      onChange={this.handleChange}
                      value={this.state.enrollkey}
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip title="Search for class">
                            <IconButton
                              aria-label="Search for class"
                              onClick={this.handleSearchClass}
                            >
                              <SearchOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                      sx={{ my: { xs: 1, md: 2 } }}
                      // error={this.state.enrollkey === ""}
                    />
                  </FormControl>
                </Grid>
                {openGrow && (
                  <Grid item xs={12} sm={12} md={6} lg={8}>
                    <Grow in={openGrow}>
                      <Box sx={{ p: 2, border: "1px solid grey" }}>
                        <Grid container spacing={2} m={1}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ bgcolor: "text.hint" }}
                          >
                            Class name
                          </Grid>
                          <Grid item xs={12} md={8}>
                            {myclass.className}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ bgcolor: "text.hint" }}
                          >
                            Instructor
                          </Grid>
                          <Grid item xs={12} md={8}>
                            {myclass.instructor}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ bgcolor: "text.hint" }}
                          >
                            Subject Area
                          </Grid>
                          <Grid item xs={12} md={8}>
                            {myclass.subjectArea}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ bgcolor: "text.hint" }}
                          >
                            Student Level
                          </Grid>
                          <Grid item xs={12} md={8}>
                            {myclass.studentLevel}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ bgcolor: "text.hint" }}
                          >
                            Start Date
                          </Grid>
                          <Grid item xs={12} md={8}>
                            {myclass.startDate.substring(0, 10)}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ bgcolor: "text.hint" }}
                          >
                            End Date
                          </Grid>
                          <Grid item xs={12} md={8}>
                            {myclass.endDate.substring(0, 10)}
                          </Grid>

                          {!myclass.enrolled && (
                            <Grid item xs={12} md={4}>
                              <Button
                                onClick={this.handleenroll}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, mx: 0 }}
                              >
                                <span>Enroll</span>
                              </Button>
                            </Grid>
                          )}
                          {myclass.enrolled && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ bgcolor: "text.hint" }}
                              >
                                Status
                              </Grid>
                              <Grid item xs={12} md={8}>
                                Enrolled
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Box>
                    </Grow>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          {this.state.myclasses && (
            <Grid item xs={12}>
              <Paper
                elevation={24}
                sx={{ my: { xs: 0, md: 0 }, p: { xs: 2, md: 3 } }}
              >
                <Grid
                  container
                  spacing={6}
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Instructor</TableCell>
                            <TableCell align="left">Enrollment Key</TableCell>
                            <TableCell align="left">Subject Area</TableCell>
                            <TableCell align="left">Student Level</TableCell>
                            <TableCell align="left">Start Date</TableCell>
                            <TableCell align="left">End Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* TODO add interface */}
                          {myclasses.map((row: Class) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                <Link to={"/myclassdetail/" + row.id}>
                                  {row.className}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                {row.instructor}
                              </TableCell>
                              <TableCell align="left">
                                {row.enrollkey}
                              </TableCell>
                              <TableCell align="left">
                                {row.subjectArea}
                              </TableCell>
                              <TableCell align="left">
                                {row.studentLevel}
                              </TableCell>
                              <TableCell align="left">
                                {row.startDate.substring(0, 10)}
                              </TableCell>
                              <TableCell align="left">
                                {row.endDate.substring(0, 10)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
        <Snackbar
          open={openSB}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={message}
        />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Main>
    );
  }
}

// TODO add interface
function mapStateToProps(state: any) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(EnrollInClass);
