import React, { useEffect, useState } from "react";
import { TimeMetricProps } from "../../utils/properties";

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Skeleton,
  IconButton,
  styled,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatDuration } from "date-fns";

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TimeMetric: React.FC<TimeMetricProps> = (props) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    // @ts-ignore
    setExpanded(props.metrics===undefined);
  }, [props.metrics]);
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader
        sx={{ height: 50, mt: 0 }}
        avatar={
          <Avatar sx={{ bgcolor: "#5b3283" }}>
            <AccessTimeIcon />
          </Avatar>
        }
        action={
          <ExpandMore
            sx={{ pt: 0 }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Time Metrics"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {props.metrics!==undefined ? (
            <>
              <p>
                <b>Active writing time:</b>{" "}
                {formatDuration(
                  {
                    days: props.metrics.active?.d,
                    hours: props.metrics.active?.h,
                    minutes: props.metrics.active?.m,
                  },
                  {
                    delimiter: ", ",
                  }
                )}
              </p>
              <p>
                <b>Think/pause time:</b>{" "}
                {formatDuration(
                  {
                    days: props.metrics.think?.d,
                    hours: props.metrics.think?.h,
                    minutes: props.metrics.think?.m,
                  },
                  {
                    delimiter: ", ",
                  }
                )}
              </p>
              <p>
                <b>Total writing time:</b>{" "}
                {formatDuration(
                  {
                    days: props.metrics.real?.d,
                    hours: props.metrics.real?.h,
                    minutes: props.metrics.real?.m,
                  },
                  {
                    delimiter: ", ",
                  }
                )}{" "}
              </p>
              
             
            </>
          ) : (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default TimeMetric;
