import React from "react";

class InstCollab extends React.Component {
  componentDidMount() {
    // TODO add interface
    const formformExists: any = document.getElementById(
      "collabora-submit-form"
    );
    if (formformExists) {
      formformExists.submit();
    }
  }

  reloadDocument() {
    // TODO add interface
    const formformExists: any = document.getElementById(
      "collabora-submit-form"
    );

    if (formformExists) {
      formformExists.submit();
    }
  }

  render() {
    return (
      <iframe
        title="Collabora Online Viewer fullscreen"
        id="collabora-online-viewer-fs"
        name="collabora-online-viewer-fs"
        // onKeyDown={(e) => console.debug(e)}
      ></iframe>
    );
  }
}

export default InstCollab;
