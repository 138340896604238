import React from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as dateService from "../../../../services/date.service";
import { useTheme } from "@mui/material/styles";

export default function AssignmentRow(props: any) {
  // TODO add interface
  const [open, setOpen] = React.useState(false);
  const { assignment } = props;
  const theme = useTheme();
  const dangerColor = theme.palette.error.light;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{assignment.title}</TableCell>
        <TableCell align="left">{assignment.startDate}</TableCell>
        <TableCell align="left">{assignment.endDate}</TableCell>
        <TableCell align="left">
          {assignment.autoSubmission ? "Yes" : "No"}
        </TableCell>
        <TableCell align="left">
          {assignment.autoEvaluate ? "Yes" : "No"}
        </TableCell>
        <TableCell align="left">
          {assignment.preventAfterDueDate ? "Yes" : "No"}
        </TableCell>
        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Students
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>createDate</TableCell>
                    <TableCell align="left">Last status change</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Grade</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.assignment.studentSubmissions.map((student: any) => {
                    return (
                      <>
                        <TableRow key={student.id}>
                          <TableCell component="th" scope="row">
                            {student.lastName},{" "}
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {student.firstName.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>{student.email}</TableCell>
                          <TableCell>
                            {dateService.toTimeZone(
                              student.submissionDetails.createDate
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: student.submissionDetails.late
                                ? dangerColor
                                : "inherit",
                            }}
                          >
                            {dateService.toTimeZone(
                              student.submissionDetails.lastChangeDate
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: student.submissionDetails.late
                                ? dangerColor
                                : "inherit",
                            }}
                            align="right"
                          >
                            {student.submissionDetails.status}
                          </TableCell>
                          <TableCell align="right">
                            {student.submissionDetails.score === -1
                              ? "-"
                              : student.submissionDetails.score}
                          </TableCell>
                          <TableCell>
                            <Button
                              fullWidth
                              variant="contained"
                              href={
                                "/educoordinator/ReadSubmission/" +
                                student.submissionDetails.submissionId
                              }
                              target="_blank"
                            >
                              Read
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
