import React from "react";
import {
  TextField,
  Button,
  Avatar,
  FormControlLabel,
  CssBaseline,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CopyRight from "../../elements/public/copy-right";
import Main from "../../elements/public/main";
import { connect } from "react-redux";
import { login } from "../../actions/auth";

// TODO add interface
class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  // TODO add interface
  onChangeUsername(e: any) {
    this.setState({
      username: e.target.value,
    });
  }

  // TODO add interface
  onChangePassword(e: any) {
    this.setState({
      password: e.target.value,
    });
  }

  // TODO add interface
  handleLogin(e: any) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const { dispatch, history } = this.props;
    dispatch(login(this.state.username, this.state.password))
      .then(() => {
        history.push("/profile");
        window.location.reload();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { isLoggedIn, message } = this.props;

    if (isLoggedIn) {
      window.location.replace("/profile");
    }
    return (
      <Main sx={{ m: { xs: 0, md: 0 }, pl: 8 }}>
        <Grid container component="main" sx={{ height: { xs: "86vh" } }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url(" + process.env.PUBLIC_URL + "/splash.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" onSubmit={this.handleLogin}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="UserName"
                  name="username"
                  autoFocus
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  variant="standard"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  variant="standard"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      id="rememberMe"
                      color="primary"
                    />
                  }
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <span>Sign In</span>
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={this.state.loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <Grid container>
                  <Grid item xs>
                    <Link href="/ForgetPassword" variant="body2">
                      Forgot password or username?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/Register" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <CopyRight sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Main>
    );
  }
}

function mapStateToProps(state: any) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;

  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Login);
