import React from "react";

const Privacy = () => {
  const TC_URL: string | undefined = process.env.REACT_APP_PRIVACY;
  return (
    <div>
      <iframe
        style={{ width: "95vw", height: "90vh" }}
        id="docs-online-viewer"
        title="student Guide"
        src={TC_URL}
      ></iframe>
    </div>
  );
};

export default Privacy;
