import React, { useState, useCallback, useEffect } from "react";
import { DeadlineSubmission } from "../../../../../store/ReadAssignment/model";
import {
  Button,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setDraftPermissionAction } from "../../../../../store/ReadAssignment/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface ExceptDraftProps {
  submissions: DeadlineSubmission[];
  submissionId: string;
}
const ExceptDraft: React.FC<ExceptDraftProps> = ({
  submissions,
  submissionId,
}) => {
  const [selected, setSelected] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSelection = () => {
      const grantedDrafts = submissions.filter((c) => c?.exception === true);
      const index = grantedDrafts.length - 1;
      if (index > -1) {
        setSelected(grantedDrafts[index].draftSubmissionId);
      }
    };
    initialSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
  };

  const selectedItem = useCallback((): DeadlineSubmission | undefined => {
    return submissions.find((c) => c.draftSubmissionId === selected);
  }, [selected, submissions]);

  const handleChangePermission = async () => {
    const item = selectedItem();
    const status = item ? !item.exception : undefined;
    if (status === undefined) return;
    // console.log(submissionId,selected,status);
    // @ts-ignore
    await dispatch(setDraftPermissionAction(submissionId, selected, status));
  };

  return (
    <Stack>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Draft</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selected}
          onChange={handleSelect}
          label="Draft"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {submissions.map((item, index) => (
            <MenuItem
              value={item.draftSubmissionId}
              key={`dr-${item.draftSubmissionId}`}
            >
              {`${item.title} - ${item.status}`}
              {item?.exception && (
                <ListItemIcon sx={{ ml: "auto", color: "green" }}>
                  <CheckCircleIcon />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedItem() && (
        <Button fullWidth onClick={handleChangePermission}>
          {selectedItem()?.exception ? "Revoke" : "Grant"}
        </Button>
      )}
    </Stack>
  );
};

export default ExceptDraft;
