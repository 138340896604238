export const CONSTRAINTS = {
    HOST: process.env.REACT_APP_API_URL,
    SUPPORT:"api/support",
    PROJECTS:"projects",
    TRACKERS:"trackers",
    ISSUES:"issue",
    CONTACT:"contactus",
    PROJECT_NAME: "antecedent-bug-reposrt",
    API_KEY_VALUE :process.env.REACT_APP_SUPPORT_API_KEY,
    API_KEY_NAME: "X-Redmine-API-Key",
    EMAIL : "api/email",
    EMAIL_CONTACT : "sendcontact",
}

export const MESSAGES = {
    SUCCESS:`Thank you for contacting us. A ticket has been created for this issue. A member of our team will contact you in short order.`,
    ERROR: `An error occured during processing your ticket. Please try later.`
}