import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../../services/auth-header";

const HOST = process.env.REACT_APP_API_URL;
const GRAPH = "api/student/graph"
const ESSAY_MAP = "essaymap";
const HEAT_MAP = "heatmap";
const WORDCHANGE = "wordchange";

export const getEssayMapData = async (id: string) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: authHeader(),
      method: "POST",
      url: `${HOST}/${GRAPH}/${ESSAY_MAP}`,
      params: {id},
      data: {  },
    };
    try {
      const apiCall: AxiosResponse<string> = await axios(axiosConfig);
      return apiCall;
    } catch (err) {
      throw err;
    }
  };
  
  export const getHeatMapData = async (id: string) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: authHeader(),
      method: "POST",
      url: `${HOST}/${GRAPH}/${HEAT_MAP}`,
      params: {id},
      data: {  },
    };
    try {
      const apiCall: AxiosResponse<string> = await axios(axiosConfig);
      return apiCall;
    } catch (err) {
      throw err;
    }
  };
  
  export const getWordChangeData = async (id: string) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: authHeader(),
      method: "POST",
      url: `${HOST}/${GRAPH}/${WORDCHANGE}`,
      params: {id},
      data: {  },
    };
    try {
      const apiCall: AxiosResponse<string> = await axios(axiosConfig);
      return apiCall;
    } catch (err) {
      throw err;
    }
  };
  