import React from "react";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { SearchProps } from "./model";
import { allValuesEmpty } from "../../UserManagement/Modification/services";

interface SubmissionSearchProps {
  searchData: SearchProps;
  setSearchData: (data: SearchProps) => void;
  handleLoad:()=>void;
}

const SubmissionSearch: React.FC<SubmissionSearchProps> = ({
  searchData,
  setSearchData,
  handleLoad,
}) => {
  const handleChange = (e: any) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleLoad();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        component={Paper}
        spacing={1}
        sx={{ p: 2 }}
        alignItems="end"
      >
        <Grid item xs={12}>
          Load Submission
          <hr />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            required={allValuesEmpty(searchData)}
            id="submissionId"
            name="submissionId"
            label="Submission Id"
            fullWidth
            variant="standard"
            margin="normal"
            onChange={handleChange}
            value={searchData.submissionId}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button type="submit" fullWidth variant="outlined">
            Load
          </Button>
        </Grid>
      </Grid>{" "}
    </form>
  );
};

export default SubmissionSearch;
