import React, { useEffect, useRef } from "react";
import { ReviewDialogProps } from "../model";
import { TransitionProps } from "@mui/material/transitions";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import authToken from "../../../../services/auth-token";
import SendIcon from "@mui/icons-material/Send";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const host: string = process.env.REACT_APP_PEERREVIEW || "";

const PeerReviewDialog: React.FC<ReviewDialogProps> = ({
  open,
  review,
  handleClose,
  handleSubmit,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const reloadDocument = () => {
      if (formRef.current) {
        formRef.current.submit();
      }
    };
    if (review) setTimeout(reloadDocument, 500);
  }, [review]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Peer Review -{" "}
            {review &&
              `(${review.assignmentTitle}, ${review.draftTitle}, ${review.studentName})`}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleClose}
            startIcon={<HelpOutlineIcon />}
            sx={{ rm: 2 }}
          >
            Help
          </Button>
          {review && review.status !== "SUBMITTED" && (
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleSubmit(review.id)}
              startIcon={<SendIcon />}
            >
              Submit
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Container
        maxWidth={false}
        sx={{
          contain: "layout",
          height: ["inherit ", "-webkit-fill-available"],
          width: "95vw",
          pt: 2,
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "88vh" }}>
          <form
            ref={formRef}
            action={`${host}${review?.fileId}`}
            encType="multipart/form-data"
            method="post"
            target="collabora-online-viewer"
            id="collabora-submit-form"
          >
            <input
              name="access_token"
              value={authToken()}
              type="hidden"
              id="access-token"
            />
            <input
              name="css_variables"
              value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
              type="hidden"
            />

            <input
              name="ui_defaults"
              value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
              type="hidden"
            />
          </form>
          <Container
            maxWidth={false}
            sx={{
              contain: "layout",
              height: ["inherit ", "-webkit-fill-available"],
            }}
          >
            <iframe
              title="Collabora Online Viewer"
              id="collabora-online-viewer"
              name="collabora-online-viewer"
              allowFullScreen={true}
            ></iframe>
          </Container>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default PeerReviewDialog;
