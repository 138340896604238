import styled from "@emotion/styled";

export const TimeMetricsContainer = styled.div`
  p {
    margin: 3px 0px;
  }
  margin-top: 10px;
`;

interface LastSubmissionDateProps {
  $isLate?: boolean;
}

export const LastSubmissionDate = styled.span<LastSubmissionDateProps>`
  color: ${(props) => (props.$isLate ? "red" : "green")};
`;
