export interface NameSearchProps {
  id: string;
  name: string;
}

export interface SearchSubmissionRequest {
  userId: string;
  enrollmentKey: string;
}

export const initSearchState = {
  userId: "",
  enrollmentKey: "",
};

export interface SearchSubmissionResponse {
  className: string;
  instructorName: string;
  submissionId: string;
  title: string;
}
