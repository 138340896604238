import "./styles.css";

interface Item {
  text: string;
  color: string;
}
interface LegendProps {
  items: Item[];
}
export default function Legend(props: LegendProps) {
  return (
    <ul className="legend">
      {props.items.map((item) => {
        return (
          <li>
            <span
              style={{
                backgroundColor: item.color,
              }}
            ></span>{" "}
            {item.text}
          </li>
        );
      })}
    </ul>
  );
}
