import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { FeatureDetails, PrivacyFeatureDetails } from "./constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

const Features = (props: any) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ mt: 2, backgroundColor: "#F4FEFF" ,pb:5}}
      {...props}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", my: 5 }}
      >
        <Typography variant="h2" color="primary" sx={{ fontWeight: "bold" }}>
          Major Features
        </Typography>
      </Grid>
      <Grid xs={8}>
        <Grid container spacing={3} sx={{justifyContent:"center"}}>
          {FeatureDetails.map((item) => (
            <Grid item xs={12} md={4}>
              <Stack>
                <CheckCircleIcon />
                <Typography variant="subtitle1" align="center">
                  {item}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", my: 5 }}
      >
        <Typography variant="h2" color="primary" sx={{ fontWeight: "bold" }}>
          Privacy Features
        </Typography>
      </Grid>
      <Grid xs={8}>
        <Grid container spacing={3} sx={{justifyContent:"center"}}>
          {PrivacyFeatureDetails.map((item) => (
            <Grid item xs={12} md={4}>
              <Stack>
                <PrivacyTipIcon />
                <Typography variant="subtitle1" align="center">
                  {item}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Features;
