import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import {EmbededAssignment as Assignment, Student } from "../../utils/model";
import AssignmentRow from "./AssignmentRow";

interface AssignmentsProps {
  assignments: Assignment[];
  students: Student[];
 
  
}
export default function Assignments(props: AssignmentsProps) {
  const getMappedAssignmentStudents = () => {
    const list: any[] = props.assignments?.map((assignment) => {
      let assignStudents: any = {
        studentSubmissions: [],
      };
      props.students.map((student) => {
        const submissionsFound = student.submissions.filter(
          (submission) => submission.assignmentId === assignment.id
        );
        if (submissionsFound.length >= 1) {
          assignStudents.studentSubmissions.push({
            ...student,
            submissionDetails: submissionsFound[0],
          });
        }
        return assignStudents;
      });
      return {
        ...assignment,
        ...assignStudents,
      };
    });
    return list;
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">Deadline</TableCell>
            <TableCell align="left">Autosubmit</TableCell>
            <TableCell align="left">Autoevaluate</TableCell>
            <TableCell align="left">Deadline enforcement</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {getMappedAssignmentStudents().map((assignment: Assignment) => (
            <AssignmentRow
              assignment={assignment}
             
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
