import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { parseISO, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { FormDataProp } from "../containers/profile/model";
import authHeader from "./auth-header";

const HOST = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL + "/api/auth/";

export function login(username: string, password: string) {
  return axios
    .post(API_URL + "signin", { username, password })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
}

export function logout() {
  localStorage.removeItem("user");
}

export function toTimeZone(date: string) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(
    utcToZonedTime(parseISO(date), timeZone),
    "yyyy-MM-dd HH:mm:ss a"
  );
}
export function validateExpiry() {
  const expiry = toTimeZone(
    //@ts-ignore
    JSON.parse(localStorage.getItem("user")).expiry
  );
  const now = format(new Date(), "yyyy-MM-dd HH:mm:ss a");
  if (expiry < now) {
    return false;
  }
  return true;
}

export function validateLogin() {
  //@ts-ignore
  const user: User = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return false;
  }
  return true;
}

export const recoverPassword = async (
  id: string,
  email: string,
  username: string,
  password: string
) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${HOST}/api/auth/recoverpassword`,
    data: {
      id,
      email,
      username,
      password,
    },
  };
  try {
    const apiCall: AxiosResponse<String> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const validateRecoveryToken = async (token: string) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${HOST}/api/auth/validaterecoverytoken`,
    data: {},
    params: { id: token },
  };
  try {
    const apiCall: AxiosResponse<Number> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (email: string) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${HOST}/api/auth/getPasswordrecoverylink`,
    data: {},
    params: { email },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const register = async (
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  phone: string,
  password: string,
  instructor: boolean,
  profile: string
) => {
  try {
    const register: AxiosResponse<string> = await axios.post(
      API_URL + "signup",
      {
        firstName,
        lastName,
        username,
        email,
        phone,
        password,
        instructor,
        profile,
      }
    );
    return register;
  } catch (err) {
    throw err;
  }
};

export const confirmEmail = async (id: string) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${HOST}/api/auth/ConfirmEmail`,
    data: {},
    params: { id },
  };
  try {
    const apiCall: AxiosResponse<boolean> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const updatePassword = async (formData: FormDataProp) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    headers: authHeader(),
    url: `${HOST}/api/auth/changepassword`,
    data: formData,
    params: {},
  };
  try {
    const apiCall: AxiosResponse<boolean> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
