import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Review } from "../../../services/models/classes.model";
import { DataProps } from "./model";
import authHeader from "../../../services/auth-header";

const HOST = process.env.REACT_APP_API_URL;
const PEER_REVIEW ="api/peerreview";
const SUBMIT = "submit"

export const handlePeerReviewDialog = (
  reviewId: string,
  state: DataProps,
  setState: React.Dispatch<React.SetStateAction<DataProps>>
): void => {
  //   setState({ ...state, review:Review, });

  const selectedReview: Review | undefined = state.myclass?.reviews.find(
    (c) => c.id === reviewId
  );
  setState({ ...state, review: selectedReview ? selectedReview : null });
};


export const submitPeerReview = async(id:string) =>{
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${PEER_REVIEW}/${SUBMIT}`,
    data: {},
    params:{id}
  };
  try {
    const apiCall: AxiosResponse<boolean> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}