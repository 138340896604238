import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import Heatmap from "./Graphs/Heatmap";
import CloseIcon from "@mui/icons-material/Close";
import {
  GraphsProps,
  initGraphProps,
  assignmentTabs,
  TabType,
  tooltipsText,
} from "./models";
import {
  getassignmentHeatMapLogs,
  getassignmentLineGraphLogs,
  getassignmentTimeSeriesLogs,
} from "../../../../../services/classes.service";
import LineGraph from "./Graphs/LineGraph";
import TimeSeries from "./Graphs/TimeSeries";
import { useSelector } from "react-redux";
import { getTimeSeriesTotal } from "./services";

interface AnalyticsProps {
  assignmentId: string;
  handleClose: () => void;
}

const Analytics: React.FC<AnalyticsProps> = ({ assignmentId, handleClose }) => {
  const [currentTab, setCurrentTab] = useState<TabType>("Heatmap");
  const [data, setData] = useState<GraphsProps>(initGraphProps);

  const assignments = useSelector((state: any) =>
    state.classDetails.assignments.filter(
      (item: any) => item.id === assignmentId
    )
  );

  useEffect(() => {
    const getHeadmapData = async () => {
      const heatMapLogs = await getassignmentHeatMapLogs(assignmentId);
      setData({ ...data, HeatmapProps: JSON.parse(heatMapLogs.data) });
    };

    const getLineGraphData = async () => {
      const lineGraphLogs = await getassignmentLineGraphLogs(assignmentId);
      setData({ ...data, lineGraphProps: JSON.parse(lineGraphLogs.data) });
    };
    const getTimeSeriesData = async () => {
      const timeSeriesLogs = await getassignmentTimeSeriesLogs(assignmentId);
      setData({
        ...data,
        TimeSeriesProps: [
          { id: "Words Changed", data: JSON.parse(timeSeriesLogs.data) },
        ],
      });
    };

    if (assignmentId === "") setData(initGraphProps);
    else if (currentTab === "Heatmap" && data.HeatmapProps === null)
      getHeadmapData();
    else if (currentTab === "Hourly Activity" && data.lineGraphProps === null)
      getLineGraphData();
    else if (currentTab === "Words Changed" && data.TimeSeriesProps === null)
      getTimeSeriesData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, currentTab]);

  return (
    <Dialog
      open={assignmentId !== ""}
      onClose={handleClose}
      fullWidth
      fullScreen
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: "black" }}
            variant="h6"
            component="div"
          >
            Assignment Analytics
            {assignments[0] && ` - ${assignments[0].title}`}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: "75vh",
          }}
        >
          <Tabs
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: "divider" }}
            variant="scrollable"
            value={currentTab}
            onChange={(event: React.SyntheticEvent, newValue: TabType) =>
              setCurrentTab(newValue)
            }
          >
            {assignmentTabs.map((tab: TabType, index: number) => (
              <Tooltip
                title={tooltipsText[tab]}
                key={tab}
                placement="right-end"
              >
                <Tab
                  key={`analytic_tab_${index}`}
                  id={`analytic_tab_${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                  label={tab}
                  value={tab}
                />
              </Tooltip>
            ))}
          </Tabs>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                ml: 2,
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              {currentTab === "Heatmap" && <Heatmap data={data.HeatmapProps} />}
              {currentTab === "Hourly Activity" && (
                <LineGraph
                  data={
                    data.lineGraphProps === null
                      ? data.lineGraphProps
                      : getTimeSeriesTotal(data.lineGraphProps)
                  }
                />
              )}

              {currentTab === "Words Changed" && (
                <TimeSeries data={data.TimeSeriesProps} />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Analytics;
