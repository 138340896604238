export const SET_DRAWER_CLOSE = "SET_DRAWER_CLOSE";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";

export type ActionType = SetDrawerClose | SetDrawerOpen;

interface SetDrawerClose {
  type: typeof SET_DRAWER_CLOSE;
}
interface SetDrawerOpen {
  type: typeof SET_DRAWER_OPEN;
}

export const setDrawerOpen = (): SetDrawerOpen => ({
  type: SET_DRAWER_OPEN,
});

export const setDrawerClose = (): SetDrawerClose => ({
  type: SET_DRAWER_CLOSE,
});
