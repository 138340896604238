export const apiIds = {
  getClassDetails: "getClassDetails",
  updateClassDetails: "updateClassDetails",
  deleteAssignment: "deleteAssignment",
  createAssignment: "createAssignment",
  updateAssignment: "updateAssignment",
  deleteStudent: "deleteStudent",
  updateAssignmentVisibility: "updateAssignmentVisibility",
  updateAssignmentStopStatus: "updateAssignmentStopStatus",
  updateDraftPeerReviewDeadline: "updateDraftPeerReviewDeadline",
};
