import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";

const Account = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ mt: 4 }}
    >
      {/* <Container maxWidth="md"  sx={{ py: 5, backgroundColor: "#5E22DD" }}></Container> */}
      <Grid
        item
        xs={10}
        sm={8}
        md={6}
        sx={{
          background: "radial-gradient(circle at center, #5E80DD 0%,  #5E22DD 100%)",
          py: 5,
          minHeight: "30vh",
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack>
          <Typography variant="h2" sx={{ color: "white", fontWeight: 400 }} className="fade-in">
            Sign up for free testing access today.
          </Typography>
          <Stack
            direction="row"
            spacing={4}
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <Button
              size="large"
              variant="outlined"
              sx={{ borderColor: "white", color: "white" }}
              href="/login"
            >
              Sign in
            </Button>
            <Button size="large" variant="contained" href="/register">
              Sign up
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Account;
