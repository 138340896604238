import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface HeatmapProps {
  data:any|null;
}

const options: ApexOptions = {
  chart: {
    height: "70vh",
    type: "heatmap",
    
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#52AEBD"],

  title: {
    text: "HeatMap",
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      radius: 0,
      distributed: true,
      useFillColorAsStroke: true,
    },
  },
};
const Heatmap: React.FC<HeatmapProps> = ({ data }) => {

  

  return data ? (
    <ReactApexChart
      options={options}
      series={data}
      type="heatmap"
      height={800}
      
    />
  ) : (
    <>Loading...</>
  );
};

export default Heatmap;
