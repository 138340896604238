/* eslint-disable import/no-anonymous-default-export */
import { ActionType, SET_SUPPORT_CLOSE, SET_SUPPORT_OPEN } from "./actions";
import { SupportDialogState, initialState } from "./model";

export default (state = initialState, action: ActionType): SupportDialogState => {
  const { type } = action;

  switch (type) {
    case SET_SUPPORT_CLOSE:
      // @ts-ignore
      return {
        ...state,
        isSupportDialogOpen: false,
      };
    case SET_SUPPORT_OPEN:
      // @ts-ignore
      return {
        ...state,
        isSupportDialogOpen: true,
      };
    default:
      return state;
  }
};
