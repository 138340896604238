import React from "react";

import {
  Avatar,
  Backdrop,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import Main from "../../elements/public/main";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { setMessage } from "../../actions/message";
import { forgotPassword } from "../../services/auth.service";

class ForgetPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      loading: false,
      email: "",
      success: false,
    };
  }

  // TODO add interface
  handleChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // TODO add interface
  async handleSubmit(e: any) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    try {
      const forgotPwd = await forgotPassword(this.state.email);
      this.setState({
        success: true,
        loading: false,
        email: "",
      });

      this.props.dispatch(setMessage(forgotPwd.data));
    } catch (error: any) {
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      this.props.dispatch(setMessage(messages));
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { message } = this.props;

    return (
      <Main>
        <Container component="main" maxWidth="sm">
          <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box
              sx={{
                my: 0,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ m: 1, bgcolor: "secondary.main", width: 56, height: 56 }}
              >
                <PasswordOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Recover Password or Username
              </Typography>
            </Box>

            <Grid
              container
              spacing={3}
              component="form"
              onSubmit={this.handleSubmit}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <span>Recover</span>
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={this.state.loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                {message && !this.state.success && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                {message && this.state.success && (
                  <div className="form-group">
                    <div className="alert alert-success" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Main>
    );
  }
}
// TODO add interface
function mapStateToProps(state: any) {
  const { message } = state.message;

  return {
    message,
  };
}
export default connect(mapStateToProps)(ForgetPassword);
