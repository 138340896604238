import React, { useEffect, useState, useRef } from "react";
import { deriveTimelineTheme } from "react-svg-timeline";
import {
  Deadline,
  DeadlineSubmission,
} from "../../../../store/ReadAssignment/model";

import CustomizedTimeline from "./CustomizedTimeline";

import { useTheme } from "@mui/material";

import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { getShade } from "./services";
dayjs.extend(utc);
dayjs.extend(timezone);
interface CustomTimeLinePropsMaster {
  deadlines: Deadline[];
  submissions: DeadlineSubmission[];
  startDate: string;
  createdDate: string;
  timezone: string;
  branch: string;
  mainfile: string;
  handleClick: (fileId: string, branch: string) => void;
}

const ReactSvgTimeline: React.FC<CustomTimeLinePropsMaster> = (props) => {
  // @ts-ignore
  const { deadlines, submissions, startDate, createdDate, timezone } = props;
  const defaultTheme = useTheme();
  const NUTREAL = defaultTheme.palette.text.secondary;
  const OOGOING = defaultTheme.palette.secondary.light;
  const LANES = {
    deadlines: "deadlines",
    submissions: "submissions",
    comments: "comments",
  };

  const onGoingDate = (): number => {
    let maxDate = new Date(0);
    props.deadlines.forEach((item) => {
      const dt = new Date(item.draftDeadline);
      if (dt > maxDate) maxDate = dt;
    });
    props.submissions.forEach((item) => {
      const dt = new Date(item.date);
      if (dt > maxDate) maxDate = dt;
      item.commentedFiles.forEach((com) => {
        const dtt = new Date(com.date);
        if (dtt > maxDate) maxDate = dtt;
      });
    });
    if (new Date(props.createdDate) > maxDate)
      maxDate = new Date(props.createdDate);
    maxDate.setDate(maxDate.getDate() + 1);
    return new Date() > maxDate ? maxDate.getTime() : new Date().getTime();
  };

  const dateFormat = (ms: number) => {
    const date = dayjs(ms).tz(timezone);
    const formattedDate = date.tz(timezone).format("YYYY-MM-DD HH:mm");
    return `${formattedDate}(${timezone})`;
  };

  const materialTheme = useTheme();
  const theme = deriveTimelineTheme(materialTheme.palette.mode, materialTheme);

  const lanes = [
    {
      laneId: LANES.deadlines,
      label: "Drafts",
    },
    { laneId: LANES.submissions, label: "Submissions" },
    { laneId: LANES.comments, label: "Comments" },
  ];
  const [events, setEvents] = useState<any[]>([]);

  const setIsPinned = (eventId: string) => {
    var updatedData = events.map((item) =>
      "isPinned" in item ? { ...item, isPinned: false } : item
    );
    updatedData = updatedData.map((item) =>
      "isPinned" in item && item.eventId === eventId
        ? { ...item, isPinned: true }
        : item
    );
    setEvents(updatedData);
  };

  const setFileId = (eventId: string) => {
    const draftId = eventId.includes("-") ? eventId.split("-")[1] : eventId;
    const submission = props.submissions.find(
      (c) => c.draftSubmissionId === draftId
    );

    if (submission !== undefined && eventId.startsWith("PR")) {
      props.handleClick(
        eventId.split("-")[2],
        `${submission.title} Peer-Review`
      );
      setIsPinned(eventId);
      return;
    } else if (submission !== undefined && eventId.startsWith("CM")) {
      props.handleClick(
        submission.commentedFiles[0].fileId,
        `${submission.title} - Comment`
      );
      setIsPinned(eventId);
      return;
    } else if (submission !== undefined) {
      if (submission.fileId === null) return;
      props.handleClick(submission.fileId, submission.title);
      setIsPinned(eventId);
      return;
    } else if (draftId === props.mainfile) {
      setIsPinned(eventId);
      props.handleClick(draftId, "Ongoing");
    }
  };

  useEffect(() => {
    const getStatusColor = (status: string, index: number = 0): string => {
      switch (status) {
        case "MISSED":
          return getShade(index, "error");
        case "SUBMITTED_LATE":
          return getShade(index, "warning");
        case "SUBMITTED_INSTRUCTOR":
          return getShade(index, "warning");
        case "SUBMITTED":
          return getShade(index, "success");
        case "SUBMITTED_AUTO":
          return getShade(index, "success");
        default:
          return NUTREAL;
      }
    };

    setEvents([]);
    let newDeadlineEvents: any[] = [];
    let newSubmissionEvents: any[] = [];
    const sortedDeadLine = props.deadlines.sort(
      (a, b) =>
        new Date(a.draftDeadline).getTime() -
        new Date(b.draftDeadline).getTime()
    );
    if (sortedDeadLine.length > 0) {
      for (let i = 0; i < sortedDeadLine.length; i++) {
        let startDate: number = 0;
        if (i === 0) {
          startDate = new Date(props.startDate).getTime();
        } else {
          startDate =
            new Date(sortedDeadLine[i - 1].draftDeadline).getTime() + 10;
        }
        let endDate: number = new Date(
          sortedDeadLine[i].draftDeadline
        ).getTime();
        const event = {
          eventId: sortedDeadLine[i].draftId,
          tooltip: `${sortedDeadLine[i].draftTitle}\n${dateFormat(
            startDate
          )}\n${dateFormat(endDate)}`,
          laneId: LANES.deadlines,
          startTimeMillis: startDate,
          endTimeMillis: endDate,
        };
        newDeadlineEvents.push(event);
      }
    }
    if (props.createdDate !== "") {
      const event = {
        eventId: "start",
        tooltip: `Document Started\n${dateFormat(
          new Date(props.createdDate).getTime()
        )}`,
        laneId: LANES.submissions,
        startTimeMillis: new Date(props.createdDate).getTime(),
        isPinned: false,
      };

      const ongoing = {
        eventId: `OG-${props.mainfile}`,
        tooltip: "Ongoing version",
        laneId: LANES.deadlines,
        startTimeMillis: onGoingDate(),
        isPinned: true,
        color: OOGOING,
      };
      newSubmissionEvents.push(event);
      newSubmissionEvents.push(ongoing);
    }

    for (let i = 0; i < props.submissions.length; i++) {
      const eventcolor = getStatusColor(props.submissions[i].status, i);
      const startDate = new Date(props.submissions[i].date).getTime();
      const event = {
        eventId: `SB-${props.submissions[i].draftSubmissionId}`,
        tooltip: `${props.submissions[i].title}\n${
          props.submissions[i].status
        }\n${dateFormat(startDate)}`,
        laneId: LANES.submissions,
        startTimeMillis: startDate,
        color: eventcolor,
        isPinned: false,
      };

      newSubmissionEvents.push(event);
      props.submissions[i].commentedFiles.forEach((commentFile, index) => {
        const commentEvent = {
          eventId: `CM-${props.submissions[i].draftSubmissionId}-${commentFile.fileId}`,
          tooltip: `${props.submissions[i].title}`,
          laneId: LANES.comments,
          startTimeMillis:
            new Date(props.submissions[i].date).getTime() + index * 20,
          color: eventcolor,
          isPinned: false,
        };
        newSubmissionEvents.push(commentEvent);
      });
      props.submissions[i].peerReview.forEach((pr, index) => {
        const prEvent = {
          eventId: `PR-${props.submissions[i].draftSubmissionId}-${pr.fileId}`,
          tooltip: `${props.submissions[i].title}\n${
            pr.reviewerName
          }\n${dateFormat(new Date(pr.timestamp).getTime())}`,
          startTimeMillis: new Date(pr.timestamp).getTime(),
          laneId: LANES.comments,
          color: eventcolor,
          isPinned: false,
        };
        newSubmissionEvents.push(prEvent);
      });
    }
    setEvents([...newDeadlineEvents, ...newSubmissionEvents]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines, submissions, startDate, createdDate, timezone]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [timelineWidth, setTimelineWidth] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      // Now it's safe to access properties of the current ref
      const width = containerRef.current.clientWidth;
      setTimelineWidth(width);
    }
  }, []);

  return (
    <div ref={containerRef}>
      {events.length > 0 && (
        <CustomizedTimeline
          width={timelineWidth}
          height={140}
          events={events}
          lanes={lanes}
          dateFormat={dateFormat}
          theme={theme}
          onEventClick={(eventId) => {
            setFileId(eventId);
          }}
        />
      )}
    </div>
  );
};

export default ReactSvgTimeline;
