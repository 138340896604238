import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { secondToHDTime } from "../services";

interface PieChartProps {
  data: any[] | undefined;
}

const PieChart: React.FC<PieChartProps> = ({ data }) => {
  const getPercentile = (value: number): string => {
    const totalTime = data?.reduce((acc, item) => acc + item.value, 0);
    return Math.round((value / totalTime) * 100) + "%";
  };
  return (
    <>
      {data && (
        <ResponsivePie
          data={data}
          sortByValue={true}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.55}
          cornerRadius={45}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "category10" }}
          arcLinkLabelsSkipAngle={14}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabel={(e) =>
            `${secondToHDTime(e.value)}  (${getPercentile(e.value)})`
          }
          arcLabelsSkipAngle={12}
          // defs={fillPatterns}
          // fill={data.map((item, index) => ({
          //   match: { id: item.id },
          //   id: fillPatterns[index % fillPatterns.length].id, // Assigning alternating patterns
          // }))}
          animate={false}
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: -100,
              translateY: 75,
              itemsSpacing: 10,
              itemWidth: 130,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "right-to-left",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              symbolBorderColor: "white", // Add border color for visibility
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                    symbolSize: 24, // Optional: Increase size on hover
                    symbolBorderColor: "#000", // Optional: Border on hover
                  },
                },
              ],
            },
          ]}
          tooltip={({ datum: { id, value, color } }) => (
            <div
              style={{
                padding: 12,
                color,
                background: "white",
              }}
            >
              <strong>
                {id}: {secondToHDTime(value)}
                <br /> {getPercentile(value)}
              </strong>
            </div>
          )}
          theme={{
            tooltip: {
              container: {
                background: "#333",
              },
            },
          }}
        />
      )}
    </>
  );
};

export default PieChart;
