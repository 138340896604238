import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import StudentRow from "./StudentsRow";
import {
  ClassDetails,
  Student,
  StudentInit, 
} from "../../utils/model";
import { withRouter } from "../../../../common/withRouter";
import { connect } from "react-redux";


interface StudentListProps {
  students?: Student[];
  assignments?: any;
  classDetails?: ClassDetails;
}
function StudentsList(props: StudentListProps) {
  const [deleteStudent, setDeleteStudent] = useState(false);
  const [deleteStudentObject, setDeleteStudentObject] = useState<Student>({
    ...StudentInit,
  });

  const deleteStudentFunc = async (student: Student) => {
    setDeleteStudent(true);
    setDeleteStudentObject(student);
  };
  return (
    <>
      {props.students && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Student Name</TableCell>
                <TableCell align="right">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO add interface */}
              {props.students.map((row: Student) => (
                <StudentRow
                  key={row.id}
                  row={row}
                  assignments={props.assignments}
                  deleteStudent={deleteStudentFunc}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!props.students && (
        <Alert severity="error">No student registered yet</Alert>
      )}

      <Dialog
        onClose={() => {
          setDeleteStudent(false);
          setDeleteStudentObject({ ...StudentInit });
        }}
        open={deleteStudent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Remove student
          <Divider />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove student{" "}
              <b>
                {deleteStudentObject.firstName} {deleteStudentObject.lastName}
              </b>{" "}
              from <b>{props.classDetails?.className}</b>?
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteStudent(false);
            }}
          >
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog>
    </>
  );
}
function mapStateToProps(state: any) {
  const { classDetails, loader } = state;
  return {
    classDetails,
    loader,
  };
}
export default withRouter(connect(mapStateToProps)(StudentsList));
