export type AnalyticsTabs = "EssayMap" | "HeatMap" | "WordChange";
export interface AnalyticsData {
  tab: AnalyticsTabs;
  essayMapData:string|undefined; 
  heatMapData:any|undefined; 
  wordChangeData:string|undefined; 
}

export const initAnalyticsData: AnalyticsData = {
  tab: "EssayMap",
  essayMapData:undefined,
  heatMapData:undefined,
  wordChangeData:undefined,
};
