import React from "react";
import Main from "../elements/public/main";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { submitContact } from "../services/util-apis.service";

class ContactUs extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleContact = this.handleContact.bind(this);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }
  handleChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async handleContact(e: any) {
    e.preventDefault();
    try {
      await submitContact(
        this.state.name,
        this.state.email,
        this.state.message
      );
      this.setState({
        name: "",
        email: "",
        message: "",
      });
    } catch (error: any) {
      // console.log(JSON.stringify(error));
    }
  }

  render() {
    return (
      <Main>
        <Grid container component="main" sx={{ height: "40vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={12}
            md={12}
            sx={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/hero-bg.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="dark-overlay">
              <Container
                fixed
                maxWidth="md"
                sx={{
                  color: "white",
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Typography variant="h1">Contact Us</Typography>
              </Container>
            </div>
          </Grid>
        </Grid>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={3}
            component="form"
            onSubmit={this.handleContact}
          >
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                variant="standard"
                margin="normal"
                onChange={this.handleChange}
                value={this.state.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                margin="normal"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextareaAutosize
                minRows={7}
                aria-label="maximum height"
                name="message"
                id="message"
                placeholder="Message"
                onChange={this.handleChange}
                defaultValue={this.state.message}
                value={this.state.message}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <span>Send</span>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Main>
    );
  }
}

export default ContactUs;
