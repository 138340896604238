import { User } from "./models/auth-token.model";

export default function authToken() {
  // @ts-ignore adding this to ignore the annoying ts error
  const user: User = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    // For Spring Boot back-end
    return user.accessToken;

    // for Node.js Express back-end
    // return { "x-access-token": user.accessToken };
  } else {
    return "";
  }
}
