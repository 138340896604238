import React, { ChangeEvent, useEffect, useState } from "react";
import { User, UserModifyProps, userInitState } from "./model";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { UpdateUserAction } from "./services";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLoaded,
  setIsLoading,
} from "../../../../store/UIActions/Loading/actions";
import { setSnackbarMessage } from "../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../store/UIActions/Snackbar/model";
import { TransitionProps } from "@mui/material/transitions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const sizes = {
  xs: 12,
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const UserModify: React.FC<UserModifyProps> = (props) => {
  const [currentUser, setCurrentUser] = useState<User>(userInitState);

  const dispatch = useDispatch();
  const loadingIds: any = useSelector((state: any) => state.loader.loadingIds);

  useEffect(() => {
    setCurrentUser(props.user);
  }, [props]);

  const handleChange = (e: any) => {
    if (e.target.type === "checkbox") {
      setCurrentUser({ ...currentUser, [e.target.name]: e.target.checked });
      return;
    }
    setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
  };

  const handleTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedProfile = event.target.value.split(/\r?\n|\r/);
    setCurrentUser((prevState) => ({
      ...prevState,
      profile: updatedProfile,
    }));
  };

  const handleSelectRole = (id: string) => {
    var roles = currentUser.roles;
    roles = roles.map((rl) => {
      return { ...rl, selected: rl.id !== id ? rl.selected : !rl.selected };
    });
    setCurrentUser({ ...currentUser, roles: roles });
  };

  const handleUpdate = async (e: any) => {
    e.preventDefault();
    try {
      dispatch(setIsLoading("UPDATE_USER"));
      const response = await UpdateUserAction(currentUser);
      if (response.status === 200) {
        const { data } = response;
        setCurrentUser(data);
        props.handleUpdateResult(data);
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.SUCCESS,
            showSnackBar: true,
            message: `User update has been completed successfully`,
          })
        );
      }
    } catch (e: any) {
      let errorMessage = "An error occurred";

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      } else if (e.message) {
        errorMessage = e.message;
      }
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: errorMessage,
        })
      );
    } finally {
      dispatch(setIsLoaded("UPDATE_USER"));
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll="paper"
      maxWidth="md"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Button
          onClick={props.handleClose}
          startIcon={<ArrowBackIcon />}
          sx={{color:"ButtonText"}}
        ></Button>
        {currentUser.id !== "" ? "Edit User" : "Add New User"}
      </DialogTitle>

      <DialogContent dividers>
        <Grid container component="form" spacing={3} onSubmit={handleUpdate}>
          <Grid item {...sizes}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={currentUser.firstName.toUpperCase()}
            />
          </Grid>
          <Grid item {...sizes}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last name"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={currentUser.lastName.toUpperCase()}
            />
          </Grid>
          <Grid item {...sizes}>
            <TextField
              required
              id="username"
              name="username"
              label="Username"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={currentUser.username}
            />
          </Grid>
          <Grid item {...sizes}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={currentUser.email.toLowerCase()}
            />
          </Grid>
          <Grid item {...sizes}>
            <TextField
              
              id="phone"
              name="phone"
              label="Phone Number"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={currentUser.phone}
            />
          </Grid>
          <Grid item {...sizes}>
            <FormControlLabel
              // sx={{ ml: 0 }}
              control={
                <Checkbox
                  color="primary"
                  name="confirmed"
                  id="confirmed"
                  onChange={handleChange}
                  checked={currentUser.confirmed}
                />
              }
              label="Confirmed? "
              labelPlacement="start"
            />
          </Grid>
          <Grid item {...sizes}>
            <TextareaAutosize
              name="profile"
              aria-label="Institutional profile"
              minRows={7}
              placeholder="Institutional profile"
              value={currentUser.profile.join("\n")}
              onChange={handleTextArea}
            />
          </Grid>
          <Grid item {...sizes}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {currentUser.roles.map((role) => {
                const key = `role-${role.id}`;
                return (
                  <ListItem key={`base-${key}`} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleSelectRole(role.id)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={role.selected}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": key }}
                        />
                      </ListItemIcon>
                      <ListItemText id={key} primary={role.name.substring(5)} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item {...sizes}>
            <Button type="submit" variant="contained" fullWidth>
              {currentUser.id === "" ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingIds.length > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default UserModify;
