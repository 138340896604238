import React from 'react';
import { RewindDialogProps } from '../../utils/properties';
import {Dialog,AppBar,Toolbar,IconButton,Typography,Backdrop,CircularProgress} from "@mui/material";
import WritingProgress from '../../../instructor/ReadAssignment/Rewind/Dashboard';
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';
import { apiIds } from '../../../../store/ReadAssignment/constants';

import Transition from "../../utils/Transition";

const RewindDialog:React.FC<RewindDialogProps> = (props) => {
    const loadingIds: any = useSelector(
        (state: any) => state.loader.loadingIds
      ); 

    return (
        <Dialog
          fullScreen
          open={props.rewindOpen}
          onClose={props.handleClose}
          // TODO look later
          // @ts-ignore
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Rewind Essay
              </Typography>
            </Toolbar>
          </AppBar>
          {loadingIds.includes(apiIds.getSubmissionLogs) ? (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loadingIds.includes(
                apiIds.getSubmissionLogs
              )}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <WritingProgress />
          )}
        </Dialog>
    );
};

export default RewindDialog;