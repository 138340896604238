import React from "react";
import { ExampleComponentFactory, ExampleEvent, ExampleProps } from "./model";
import { Timeline } from "react-svg-timeline";
import { useTheme } from "@mui/material/styles";
import StartIcon from "@mui/icons-material/Start";
import PublishIcon from "@mui/icons-material/Publish";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";


const CustomizedTimeline: React.FC<ExampleProps> = (props) => {
  const theme = useTheme();
  const PRIMARY = theme.palette.primary.light;

  const eventComponent: ExampleComponentFactory = (
    e: ExampleEvent,
    role: string,
    timeScale: (arg0: any) => any,
    y: number
  ) => {
    const sizeIcon = e.isPinned ? 44 : 24;
    const sizeBar = 6;
    const startX = timeScale(e.startTimeMillis)!;

    if (e.endTimeMillis === undefined) {
      const iconTranslate = `translate(${startX - sizeIcon / 2}, ${
        y - sizeIcon / 2
      })`;
      return (
        <g transform={`${iconTranslate}`}>
          {e.eventId === "start" && (
            <StartIcon
              sx={{
                color: e.color,
                fill: e.color ? e.color : PRIMARY,
                strokeWidth: 2,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("SB-") && (
            <PublishIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("OG-") && (
            <RunCircleIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("CM-") && (
            <RateReviewIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
          {e.eventId.startsWith("PR-") && (
            <SwapHorizontalCircleIcon
              sx={{
                strokeWidth: 2,
                color: e.color,
                // stroke: e.color,
                fill: e.color ? e.color : PRIMARY,
              }}
              width={sizeIcon}
              height={sizeIcon}
            />
          )}
        </g>
      );
    } else {
      const endX = timeScale(e.endTimeMillis)!;
      const width = endX - startX;
      return (
        <g>
          <rect
            color={PRIMARY}
            fill={PRIMARY}
            stroke={PRIMARY}
            strokeWidth={"1px"}
            x={startX}
            y={y - sizeBar / 2}
            width={width - 5}
            height={sizeBar}
            rx={5}
          />
        </g>
      );
    }
  };

  return <Timeline {...props} eventComponent={eventComponent} />;
};

export default CustomizedTimeline;
